.swiper-pagination{
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	left: 0;
}
.swiper-pagination-bullet{
	display: block;
	border-radius: 50%;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
	outline: none;
}
