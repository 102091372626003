
.c-sns{
	padding: 45px 15px 0;
}
.c-sns__list{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 70.37%;
	margin-left: auto;
	margin-right: auto;
	@include mqPc {
		justify-content: center;
	}
}
.c-sns__item{
	width: 22.36%;
	@include mqPc {
		width: 46px;
		margin: 0 17px;
		svg{

		}
		&:hover{
			svg{
				.bg-gold{
					fill: none;
				}
				.bg-none{
					fill: #9f8b49;
				}
			}
		}
	}
}
.c-sns__link{

}

.c-flex{
	display: flex;
	flex-wrap: wrap;
}


