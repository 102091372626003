//  setting
@import "variables";

html {
	font-family: $baseFontsFamily;
	font-size: 62.5%;
	line-height: $baselineHeight;
	color:$baseFontsColor;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;

}

body{
	font-size: $baseFontSize;
	min-width: 320px;
	background: #ece7d9;
	width: 100%;
	max-width: 100%;
	position: relative;
}

a {
	color: #333;
	text-decoration: underline;

	@include mqPc {
		transition: opacity .6s;
		&:hover{
			opacity: .85;
			text-decoration: none;

		}
		&[href*="tel:"] {
			pointer-events: none;
			cursor: default;
			text-decoration: none;
			}
	}
	img{

	}

}

img{
	width: 100%;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
	&.lozad{
		opacity: 0;
		transition: opacity .6s;
	}
	&[data-loaded="true"]{
		opacity: 1;
		transition: opacity .6s;
	}
}

ol, ul {
  list-style: none;
  padding-left: 0;
}

input {
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  outline: none;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

h1,h2,h3,h4,h5,h6{
	font-weight: bold;
}

a,button,label{
	cursor: pointer;
}
