.p-media{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 15px;
	@include mqPc {

		align-items: flex-start;
		padding: 0 40px;
	}
}
.c-media__heading{
	color: #9F8B49;
	font-size: 1.5rem;
	line-height: 1.5;
	@include mqPc {
		font-size: 2.2rem;
		line-height: 1.6;
	}
	&+.c-media__text{
		margin-top: fslh-spacing(10px,1.6rem,1.5);
		letter-spacing: -.0892em;
		font-weight: 300;
		@include mqPc {
			font-size: 1.5rem;
			margin-top: 0;
			letter-spacing: -.07em;
		}
	}
}

.c-media__text{
	text-align: justify;
}

.p-media__image{
	order: 2;
	margin: fslh-spacing(25px,1.2rem,1.75) 0 0 0;
	flex:0 0 100%;
	max-width: 100%;
	@include mqPc {
		order: 1;
		flex: 0 1 325px;
		max-width: 325px;
		margin-top: 0;
	}
}
.p-media__contents{
	order: 1;
	@include mqPc {
		order: 2;
		flex: 0 0 calc(100% - 325px);
		max-width: 475px;
		padding-left: 30px;
	}
}


.p-media--profile{
	@include mqPc {
		margin-top: 30px;
	}
	&:nth-of-type(n+2){
		.p-media__image{
			margin-top: 16px;
			@include mqPc {
				margin-top: 0px;
			}
		}
	}
	.p-media__image{
		margin-top: 20px;
		order: 1;
		@include mqPc {
			width: 25.5%;
			margin-top: 0;
			max-width: 204px;
		}
	}
	.p-media__contents{
		order: 2;
		margin-top: 13px;
		@include mqPc {
			width: calc(100% - 204px);
			max-width: calc(100% - 204px);
			margin-top: 0;
			padding-left: 40px;
			flex: 1 1 calc(100% - 204px);
		}
	}
	.c-media__heading{
		font-size: 1.6rem;
		line-height: 1.43;
		@include mqPc {
			font-size: 2rem;
			line-height: 1.7;
		}

		&+.c-media__text{
			margin-top: fslh-spacing(5px,1.6rem,1.75);
		}
		small,
		span{
			font-size: 1.2rem;
			line-height: 1.78;
			@include mqPc {
				font-weight: normal;
			}
		}
		span{
			@include mqPc {
				font-size: 1.4rem;
				line-height: 2.4;
			}
		}
		small{
			display: block;
			@include mqPc {
				font-size: 1.8rem;
				line-height: 1.4;
			}
		}
	}
	.c-media__text{
		font-weight: 300;
	}
	&:nth-of-type(n+2){
		margin-top: 4px;
		@include mqPc {
			margin-top: 30px;
		}
	}
}

.c-section__contents{
	.p-media{
		padding-left: 0;
		padding-right: 0;
	}
}


.top{
	.p-media{
		width: calc(100% - 30px);
		margin-left: auto;
		margin-right: auto;
		border: 1px solid #9F8B49;
		padding: 15px;
		background: #fff;
		@include mqPc {
			width: calc(100% - 70px)
		}
	}
	.c-media__heading{
		img{
			width: 190px;
			@include mqPc {
				width: 275px;
			}
		}
	}
	.c-media__text{
		@include mqPc {
			font-size: 1.4rem;
		}
	}



.p-media__image{
	@include mqPc {
		order: 1;
		flex: 0 1 365px;
		max-width: 365px;
		margin-top: 0;
	}
}
.p-media__contents{
	@include mqPc {
		flex: 0 0 calc(100% - 385px);
		max-width: 385px;
		padding-left: 20px;
	}
}
}
