.c-performers__list{
	margin-top: fslh-spacing(15px,1.5rem,1.5);
	-moz-osx-font-smoothing: inherit;
	-webkit-font-smoothing: subpixel-antialiased;
	@include mqPc {
		margin-top: fslh-spacing(25px,2rem,1.5);
	}
}
.c-performers__item{
	font-size: 1.5rem;
	line-height: 1.5;
	letter-spacing: -.14em;
	@include mqPc {
		font-size: 2rem;
		display: flex;
		justify-content: space-between;
		letter-spacing: 0em;
	}
	&:nth-of-type(n+2){
		margin-top: fslh-spacing(4.5px,1.5rem,1.5);
		@include mqPc {
			margin-top: fslh-spacing(4px,2rem,1.5);
		}
	}
	p{
		small{
			font-size: 1.2rem;
			line-height: 1.875;
			margin-left: 0px;
			@include mqPc {
				margin-left: 3px;
				font-size: 1.7rem;
				line-height: 1.94;
			}
		}
	}
	span{
		font-size: 1.2rem;
		letter-spacing: 0em;
		line-height: 1.5;
		@include mqPc {
			font-size: 1.7rem;
			line-height: 1.94;
			flex: 0 0 45.5%;
			max-width: 45.5%;
		}
	}
	&--big{
		font-size: 1.9rem;
		line-height: 1.28;
		@include mqPc {
			font-size: 2.4rem;
			line-height: 1.75;
		}
		span{
			font-size: 1.5rem;
			line-height: 1.7;
			@include mqPc {
				font-size: 2rem;
				line-height: 2.1;
			}
		}
	}
	&.u-mt-10{
		margin-top: 1em;
	}
}
