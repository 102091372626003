.l-footer {
  @include mqPc {

	}

  .l-footer__inner {
    width: 73.43%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
		flex-wrap: wrap;
		padding: fslh-spacing(16px,1rem,1.75) 0 fslh-spacing(25px,1rem,1.72);
		@include mqPc {
			width: 100%;
			max-width: 1000px;
			padding-top: 37px;
			padding-right: 60px;
			padding-bottom: 55px;
			padding-left: 60px;
		}
  }

  .footer__copptright,
  .footer__copptright--photo,
  .footer__list {
		flex: 1 0 100%;
		@include mqPc {
			flex: 1 0 50%;
			max-width: 100%;
		}
	}

	.footer__copptright,
  .footer__copptright--photo{
		font-size: 1rem;
	}

  .footer__copptright {
		order: 1;
		line-height: 1.75;
		@include mqPc {
			font-size: 1.1rem;
		}
  }

  .footer__copptright--photo {
		order: 3;
		line-height: 1.72;
		margin-top: fslh-spacing(28px,1.1rem,1.72);
		@include mqPc {
			font-size: 1rem;
			margin-top: fslh-spacing(5px,1rem,1.72);
		}
  }

  .footer__list {
		font-size: 1.1rem;
    order: 2;
    display: flex;
		flex-wrap: wrap;
		margin-top: fslh-spacing(5px,1.1rem,1.75);

    @include mqPc {
			display: flex;
			margin-top: 0;
			font-size: 1.4rem;
			justify-content: flex-end;
		}

		li{
			padding-right: 20px;
			margin-top: fslh-spacing(21px,1.1rem,1.72);
			@include mqPc {
				margin-top: 0;
				padding: 0 0 0 20px;
			}
		}
  }
}
