.l-wrapper{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 100%;
}

.l-container{
	opacity: 0;
	background: none;
	width: 84.375%;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.2rem;
	line-height: 1.75;
	font-weight: 600;
	padding-bottom: 25px;
	padding-top: 15px;
	transition: background .6s, opacity .3s;
	@include mqPc {
		padding-top: 34px;
		width: 100%;
		max-width: 880px;
		line-height: 1.875;
		font-size: 1.6rem;
		padding-bottom: 35px;
	}
	@media screen and (min-width: 769px) and (max-width: 1000px) {
		width: calc(100% - 120px);
    margin: 0 60px;
	}
}
.is-loaded{
	.l-container{
		opacity: 1;
		transition-delay: .6s;
		transition-duration: .3s;
		background: rgba(255, 255, 255, 0.7);
	}
}

.p-catch{
	&+.l-container{
		padding-top: fslh-spacing(50px,1.2rem,1.5);
	}
}

.l-main{
	width: 100%;

	@include mqPc {
		padding-top: 42px;
	}
	@include mqTb {
		padding-top: calc(42px - (1rem + 6px));
	}
}



.top{
	.l-container{
		@include mqPc {
			margin-top: 35px;
		}
	}
}
