.c-catch {
  position: relative;
	z-index: 50;
	width: 100%;
	overflow-x: hidden;

  @include mqPc {
    padding-left: 13.333vw;
  }

  &::after {
    @include mqPc {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.p-catch--background {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
	//padding-top: 66.51%;
	padding-top: 73%;
	background-image: none;
	opacity: 0;
	transition: background-image .6s, opacity .3s;
	@include mqPc {
		transition: background-image 1.6s, opacity 3s ease-out;
	}
  @include mqPc {
    padding-top: 0;
    height: 730px;
    overflow: hidden;
  }
}

.c-catch-title {
  position: absolute;
  opacity: 0;
}

.c-catch-images {
  opacity: 0;
  position: absolute;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.is-loaded{
	.p-catch--background {
		transition-timing-function: ease-in-out;
		transition-delay: .6s;
		transition-duration: 1s;
		opacity: 1;
	}
	&.vol28 {
		.p-catch--background {
			background-position: center center;
			background-image: url(./../images/vol28/vol28_img-catch_background--sp.png);
			@include mqPc {
				background-image: url(./../images/vol28/vol28_img-catch_background--pc.jpg);
			}
		}
	}
	&.vol29{
		.p-catch--background {
			background-image: url(./../images/vol29/vol29_img-catch_background--sp.png);
			@include mqPc {
				background-image: url(./../images/vol29/vol29_img-catch_background--pc.png);
			}
		}
	}
	&.vol30-31 {
		.p-catch--background {
			background-image: url(./../images/vol30-31/vol30-31_img_catch_stevem--sp.png);
			@include mqPc {
				background-image: url(./../images/vol30-31/vol30-31_img_catch_stevem--pc.png);
			}
		}
	}
	.-is-21vol01 {
		.p-catch--background {
			background-image: url(./../images/2021/hc1/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc1/catch_img--pc.png);
			}
		}
	}
	.-is-21vol02 {
		.p-catch--background {
			background-image: url(./../images/2021/hc2/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc2/catch_img--pc.png);
			}
		}
	}
	.-is-21vol03 {
		.p-catch--background {
			background-image: url(./../images/2021/hc3/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc3/catch_img--pc.png);
			}
		}
	}
	.-is-21vol04 {
		.p-catch--background {
			background-image: url(./../images/2021/hc4/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc4/catch_img--pc.png);
			}
		}
	}
	.-is-21vol05 {
		.p-catch--background {
			background-image: url(./../images/2021/hc5/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc5/catch_img--pc.png);
			}
		}
	}
	.-is-21vol06 {
		.p-catch--background {
			background-image: url(./../images/2021/hc6/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc6/catch_img--pc.png);
			}
		}
	}
	.-is-21vol07 {
		.p-catch--background {
			background-image: url(./../images/2021/hc7/catch_img--sp.png);
			@include mqPc {
				background-image: url(./../images/2021/hc7/catch_img--pc.png);
			}
		}
	}
}

.vol28 {
  .c-catch-title {
    width: 64.84%;
    top: calc(50% - 5px);
    transform: translateY(-50%);
    right: 4.21%;

    @include mqPc {
			right: auto;
			left: 31%;
			max-width: 686px;
			top: calc(50% - -7px);
		}
		@media screen and (min-width: 1200px){
			left: 432px;
		}
  }

  .c-catch-images {
    left: 0;
    top: 0;
    width: 54.37%;
    bottom: 0;
		height: 100%;
		@include mqPc {
			left: -10px;
		}

    img {
      width: auto;
			height: 100%;

    }
  }
}

.vol29 {
  .c-catch-title {
    width: 64.84%;
    left: 5.83%;
    bottom: 4.79%;
    @include mqPc {
      max-width: 840px;
    }

  }

  .c-catch-images {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @include mqPc {
      left: auto;
      right: 1.6%;
      top: auto;
      width: 77.08%;
      bottom: 0;
      height: auto;
      max-height: calc(100% - 30px);
    }

    img {
      width: auto;
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      @include mqPc {
        width: 100%;
        height: auto;
      }
    }
  }
}

.vol30-31 {
  .c-catch-title {
    width: 77.5%;
    left: 4.68%;
    bottom: 0%;
    @include mqPc {
      left: 5.83%;
      width: 81.95%;
      bottom: 30px;
      max-width: 983px;
    }
  }

  .c-catch-images {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @include mqPc {
      left: auto;
      right: 1.6%;
      top: auto;
      width: 77.08%;
      bottom: 0;
      height: calc(100% - 30px);
    }

    img {
      margin: auto;
    }
  }
}

.c-catch__title {
  padding: 0 7.81% 0 7.03%;
  position: relative;
  top: 7px;
  z-index: 10;

  @include mqPc {
    position: absolute;
    padding: 0;
    left: 5.833vw;
    bottom: 2.5vw;
    width: 57.6%;
    top: auto;
    z-index: 10;
  }
}

.c-catch__name {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;

  @include mqPc {}
}

.c-catch__images {
	position: relative;
    height: 100%;
    display: inline-block;
    width: 100%;
  .c-catch__image {
    background: #fff;
		position: relative;
		opacity: 0;
		transition: opacity .6s ease-in;

    @include mqPc {
			float: left;
			opacity: 1;

      &:nth-of-type(1) {
				width: 39.5%;

        .c-catch__name {
          top: 0;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
				width: 60.5%;
      }

      &:nth-of-type(2) {
        .c-catch__name {
          top: 0;
          bottom: auto;
        }
      }

      &:nth-of-type(3) {
        .c-catch__name {
          bottom: 0;
          left: 0;
        }
      }
    }

    img {
			width: 100%;
			height: auto;
    }

    .c-catch__name {
      img {
        width: 50%;

        @include mqPc {
          width: 50%;
        }
      }
    }
  }

  .c-catch__link {
    display: block;

    @include mqPc {
      overflow: hidden;
      min-height: 1em;

      img {
				transform: scale(1.005);
        transition: transform .65s;
      }

      &:hover {
        img {
          transform: scale(1.1);
          transition: transform .65s;
        }
      }
    }
	}

	@include mqPc {
		&::after{
			content: "";
			display: block;
			background: #fff;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
		}
	}
}

.vol28,
.vol29 ,
.vol30-31{
	.c-catch{
			&::after{
				content: "";
				display: block;
				background: #ece7d9;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0px;
				right: 0px;
				bottom: 0;
				z-index: 10;
			}
		}
}

.is-loaded {
	.c-catch-title {
		opacity: 1;
		transition-delay: 1.4s;
    transition: opacity ease-in .8s;
	}
  .c-catch-images {
    opacity: 1;
		transition: opacity ease-in 1s;
  }

	&.vol28,
	&.vol29 ,
	&.vol30-31{
		.c-catch{
				&::after{
					transform: translateX(100%);
					transition: transform .7s;
				}
		}
	}

	@include mqPc {
		.c-catch {
			.c-catch__images{
				&::after{
					transform: translateX(100%);
					transition: transform 1s;
				}
			}
		}
	}
}

.p-top-mainVisual{
	position: relative;
	padding: 0 9px;
	z-index: 10;
	@include mqPc {
		padding-left: 18px;
		padding-right: 18px;
	}
	&__title{
		position: absolute;
		top: calc(3.906vw + 9px);
		left: 7.813vw;
		width: 85vw;
		@include mqPc {
			left : 6.786vw;
			bottom: -3.214vw;
			width: 50vw;
			height: 27.143vw;
			top: auto;
			z-index: 10;
		}
	}
	&__container{
		@include mqPc {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	&__panel{
    padding-bottom: 0.625vw;
		background-color:rgba(159,139,73,0.6);
		@include mqPc {
			flex: 0 0 calc(33.3% + 1px);
			max-width: calc(33.3% + 1px);
			padding-bottom: 0;
			overflow: hidden;
			background: 0 0;
			margin-bottom: -3px;
			margin-right: -1px;
			&.-title{
				order: 6;
				flex: 0 0 calc(66.6% + 1px);
				max-width: calc(66.6% + 1px);
				img{
					width: calc(100% + 4px);
					max-width: calc(100% + 4px);
				}
			}
			&.-is-21vol01{
				order: 0;
			}
			&.-is-21vol02{
				order: 1;
			}
			&.-is-21vol03{
				order: 2;
			}
			&.-is-21vol04{
				order: 3;
			}
			&.-is-21vol05{
				order: 4;
			}
			&.-is-21vol06{
				order: 5;
			}
			&.-is-21vol07{
				order: 7;
			}
		}
		img{
			@include mqPc {
				width: calc(100% + 2px);
				max-width: calc(100% + 2px);
				height: auto;
			}
		}
	}
	&__link{
		display: block;

			@include mqPc {
				&>img,
				&>picture{
					position: relative;
					display: block;
					transition: transform .3s ease-in;
					transition: transform .3s ease-in;
					&::before{
						content: "";
						display: block;
						background-color: #000;
						opacity: .8;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						transition: opacity .3s ease-in;
					}
				}
			}

		&:hover{
			@include mqPc {
				&>img,
				&>picture{
					transform: scale(1.02);
					transition: transform .3s ease-in;
					&::before{
						opacity: .8;
					}
				}
			}
		}

	}
}

.-hc{
	.c-catch{
		@include mqPc {
			padding-left: 0;
		}
	}
	.p-catch--background{
		background-position: center center;
		transition: none;
		@include mqPc {
			padding-top: 0;
		}
	}
}

.-is-21vol01 {
	.p-catch--background {
    padding-top: 66.6%;
		@include mqPc {
			height: 796px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 61.406vw;
		height : 40.625vw;
		right: 4.375vw;
		bottom: 1.719vw;
    @include mqPc {
			bottom: 2.5vw;
			left: 3.214vw;
			width: 54.04vw;
			max-width: 756.56px;
			height: auto;
			right: unset;
			top: unset;
    }
  }
}
.-is-21vol02 {
	.p-catch--background {
    padding-top: 72.1%;
		@include mqPc {
			height: 867px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 90.625vw;
		height : 40.625vw;
		right: 4.688vw;
		bottom: 1.563vw;
    @include mqPc {
			width: 72.786vw;
			height : 26.786vw;
			left: 2.857vw;
			bottom: 2.143vw;
			right: unset;
			top: unset;
    }
  }
}

.-is-21vol03 {
	.p-catch--background {
    padding-top: 72.6%;
		@include mqPc {
			height: 928px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 83.125vw;
		height: 40.625vw;
		left: 5.469vw;
		top: 3.125vw;
    @include mqPc {
			width: 85.75vw;
			height: 31.333vw;
			top: 2.143vw;
			left: 2.857vw;
    }
  }
}

.-is-21vol04 {
	.p-catch--background {
    padding-top: 66.7%;
		@include mqPc {
			height: 858px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 87.62vw;
		height: 32.555vw;
		left: 5.469vw;
		top: 3.906vw;
    @include mqPc {
			width: 77.5vw;
			height: 19.286vw;
			top: 1.786vw;
			left: 2.857vw;
    }
  }
}

.-is-21vol05 {
	.p-catch--background {
    padding-top: 75%;
		@include mqPc {
			height: 928px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 92.503vw;
		height: 32.5vw;
		top: 3.906vw;
		left: 6.25vw;
    @include mqPc {
			width: 57.143vw;
			height: 19.5vw;
			top: 1.786vw;
			left: 2.5vw;
    }
  }
}

.-is-21vol06 {
	.p-catch--background {
    padding-top: 66.3%;
		@include mqPc {
			height: 852px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 67.2vw;
		height: 39.219vw;
		top: 3.125vw;
		left: 3.906vw;
    @include mqPc {
			width: 57.143vw;
			height: 19.5vw;
			top: 1.786vw;
			left: 2.5vw;
    }
  }
}

.-is-21vol07 {
	.p-catch--background {
    padding-top: 66.6%;
		@include mqPc {
			height: 1008px;
			padding-top: 0;
		}
	}
  .c-catch-title {
		width: 67.32vw;
		height:  39.219vw;
		top: 3.906vw;
		left: 5.469vw;
    @include mqPc {
			width: 54.771vw;
			height: 24.536vw;
			top: 1.786vw;
			left: 2.857vw;
    }
  }
}


