.c-profile__list {
	width: 88.88%;
	margin-right: auto;
	margin-left: auto;
	margin-top: 22.5px;
	@include mqPc {
    width: 100%;
    padding-right: 40px;
		padding-left: 40px;
		margin-top: 32px;
	}
}

.c-profile__item {
	@include mqPc {
		display: flex;
	}
	&:nth-of-type(n+2){
		margin-top: fslh-spacing(32px,1.2rem,1.75);
		@include mqPc {
			margin-top: 40px;
		}
	}
	&+.c-profile__item--noteBox{
		margin-top: 50px;
		@include mqPc {
			margin-top: 70px;
		}
	}
	&+.c-profile__item--type02{
		margin-top: 10px;
		@include mqPc {
			margin-top: 25px;
		}
	}
	.c-flex{
		justify-content: space-between;
	}
	.c-flex__item{
		flex: 0 1 calc(50% - 10px);
		width: calc(50% - 10px);
		@include mqPc {
		flex: 0 1 calc(50% - 40px);
		width: calc(50% - 40px);
		}
		img{
			width: calc(50% + 9px);
			height: auto;
			@include mqPc {
				width: 129px;
			}
		}
		&:nth-of-type(n+3){
			margin-top: 15px;
			@include mqPc {
				margin-top: 12px;
			}
		}
		.p-media{
			padding-left: 0;
			padding-right: 0;
			@include mqPc {
				align-items: center;
			}
		}
		.p-media__image{
			order: 1;
			margin-top: 0;
			@include mqPc {
				flex: 0 1 129px;
				max-width: 129px;
			}
		}
		.p-media__contents{
			order: 2;
			margin-top: 3px;
			@include mqPc {
				margin-top: 4px;
				flex: 0 1 calc(100% - 130px);
				width: calc(100% - 130px);
				padding-left: 26px;
			}
		}
		.c-media__heading{
			font-size: 1rem;
			font-weight: 600;
			line-height: 1.6;
			@include mqPc {
				font-size: 1.8rem;
				position: relative;
				margin-top: -25px;
				line-height: 1.9;
			}
		}
		a{
			text-decoration:none;
		}
	}
	&.c-profile__item--type02{
		display: flex;
		justify-content: space-between;
		text-decoration: none;
		.c-profile__image{
			flex: 1 1 36%;
			max-width: 36%;
			@include mqPc {
				flex: 0 1 172px;
				width: 172px;
				margin-right: 28px;
			}
		}
		.c-profile__contents{
		border-top: 1px solid #9F8B49;
		border-bottom: 1px solid #9F8B49;
		flex: 1 1 calc(64% - 10px);
		width: calc(64% - 10px);
		max-width: calc(64% - 10px);
    display: flex;
    align-items: center;
		margin-top: 0;
		@include mqPc {
			flex: 1 1 calc(100% - 200px);
			width: calc(100% - 200px);
			max-width: calc(100% - 200px);
			padding-left:0;
			}
			.c-profile__name{
				font-size: 1.1rem;
				line-height: 1.65;
				@include mqPc {
					font-size: 1.8rem;
					line-height: 1.9;
					position: relative;
					top: -2px;
				}
			}
		}
		&+.c-profile__item{
			margin-top: 21px;
			@include mqPc {
				margin-top: 23px;
			}
		}
	}
	.c-profile__item--noteBox{
    font-size: 1.2rem;
    letter-spacing: -.0892em;
    line-height: 1.75;
		@include mqPc {
			font-size: 1.5rem;
			letter-spacing: -.07em;
			line-height: 1.98;
		}
	}
}

.c-profile__image {
	@include mqPc {
		flex: 0 0 205px;
		max-width: 205px;
	}
	img{
		height: auto;
	}
}

.c-profile__contents {
	margin-top: fslh-spacing(15px,1.6rem,1.43);
	@include mqPc {
		flex: 0 0 calc(100% - 205px);
		max-width: calc(100% - 205px);
		margin-top: 0;
		padding-left: 40px;
	}
}

.c-profile__name {
	font-size: 1.6rem;
	line-height: 1.43;
	color: #9f8b49;
	@include mqPc {
		font-size: 2rem;
		line-height: 2.2;
    position: relative;
    top: -12px;
	}
	small {
		font-size: 1.2rem;
		line-height: 1.91;
		@include mqPc {
			font-size: 1.4rem;
			line-height: 2.4;
		}
	}
	span{
		display: block;
		font-size: 1.2rem;
		line-height: 1.7;
		@include mqPc {
			font-size: 1.8rem;
			line-height: 1.2;
		}
	}
}

.c-profile__text {
	font-size: 1.2rem;
	line-height: 1.75;
	font-weight: 300;
	letter-spacing: -.0892em;
	text-align: justify;
	@include mqPc {
		letter-spacing: -.07em;
		margin-top: fslh-spacing(6px,1.5rem,1.98);
		font-size: 1.5rem;
		line-height: 1.98;
	}
	a{
		display: block;
		@include mqPc {
			display: inline-block;
		}
	}
	&+.c-profile__name{
		margin-top: 15px;
		@include mqPc {
			margin-top: 30px;
		}
	}
}

.-hc{
	.c-profile__list {
		width: calc(100% - 26px);
		margin-right: auto;
		margin-left: auto;
		margin-top: 22.5px;
		@include mqPc {
			width: 100%;
			padding-right: 40px;
			padding-left: 40px;
			margin-top: 32px;
		}
	}

}
