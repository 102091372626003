@import './../../../../node_modules/jquery-simplyscroll/jquery.simplyscroll';


.p-animations {
	width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	z-index: 0;
	overflow-x: hidden;
}

.p-animations--images {
	height: 100%;
	opacity: 0;
	transition: opacity 2s;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  .vert {
    width: 100%;
		height: 100%;

    .simply-scroll-clip {
      width: 60%;
      height: 100%;
			margin-left: auto;
			img{
				animation: bgImagesFade ease-in 35s infinite;
			}
    }
  }
}


@keyframes bgImagesFade {
	0%{
		opacity: 1;
		transition: opacity 2s;
		transform: translateX(0px) translateY(0px) rotate(0deg);
	}
	25%{
		opacity: .6;
		transition: opacity 2s;
		transform: translateX(5px) translateY(5px) rotate(.6deg);
	}
	50%{
		opacity: .8;
		transition: opacity 2s;
		transform: translateX(15px) translateY(50px) rotate(-.6deg);
	}
	75%{
		opacity: .5;
		transition: opacity 2s;
		transform: translateX(5px) translateY(-15px) rotate(.4deg);
	}
	100%{
		opacity: 1;
		transition: opacity 2s;
		transform: translateX(0px) translateY(0px) rotate(0deg);
	}
}

.is-loaded{
	.p-animations--images {
		opacity: 1;
		transition-delay: 1s;
		transition-duration: 2s;
	}
	.p-animations--images_02 {
		opacity: 1;
		transition-delay: 1.4s;
		transition-duration: .8s;
	}
}

.vert .simply-scroll-clip {}

.p-animations--border {
	overflow-x: hidden;
	max-width: 100%;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	pointer-events: none;
	display: block;

	.c-animations--border__left,
	.c-animations--border__right {
    position: fixed;
    display: block;
		z-index: 100;
		opacity: 0;

    &::before,
    &::after {
      content: "";
      display: block;
      position: relative;
    }
  }

	.c-animations--border__bottom {
    position: absolute;
    display: block;
		z-index: 100;
		opacity: 0;

    &::before,
    &::after {
      content: "";
      display: block;
      position: relative;
    }
  }


  .c-animations--border__top{
    display: block;
		position: fixed;
		top: 0;
		z-index: 1;
		opacity: 0;

    &::before,
    &::after {
      content: "";
      display: block;
      position: relative;
    }
  }

  .c-animations--border__left,
  .c-animations--border__right {
    width: 9px;
    max-width: 18px;
		height: 0;
		transition: height .3s ease;
		@include mqPc {
			transition: height .6s ease;
		}
    @include mqPc {
      width: 18px;
    }
  }

  .c-animations--border__top,
  .c-animations--border__bottom {
    height: 9px;
    max-height: 18px;
    width: 0;
		transition: width .3s ease;
		@include mqPc {
			transition: width .6s ease;
		}

    @include mqPc {
      height: 18px;
    }
  }

  .c-animations--border__left {
    left: 0;
    bottom: 0;
		transition-delay: 0s;
		transition-property: height,opacity;
    &::before,
    &::after {
      width: 100%;
      height: 50%;
      right: 0;
			background: #c5b68b;
    }

    &::before {
      top: 4px;
      transform: skewY(45deg);

      @include mqPc {
        top: 9px;
      }
    }

    &::after {
      bottom: 4px;
      transform: skewY(-45deg);

      @include mqPc {
        bottom: 9px;
      }
    }
  }

  .c-animations--border__top {
    top: 0;
    transition-delay: .6s;
		transition-property: width,opacity;
    &::before,
    &::after {
      background: #9F8B49;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
    }

    &::before {
      left: 4px;
      transform: skewX(45deg);

      @include mqPc {
        left: 9px;
      }
    }

    &::after {
      right: 4px;
      transform: skewX(-45deg);

      @include mqPc {
        right: 9px;
      }
    }
  }

  .c-animations--border__right {
    right: 0;
    top: 0;
    transition-delay: .9s;
		transition-property: height,opacity;
    &::before,
    &::after {
      width: 100%;
      height: 50%;
      right: 0;
			background: #c5b68b;
    }

    &::before {
      top: 4px;
      transform: skewY(-45deg);

      @include mqPc {
        top: 9px;
      }
    }

    &::after {
      bottom: 4px;
      transform: skewY(45deg);

      @include mqPc {
        bottom: 9px;
      }
    }
  }

  .c-animations--border__bottom {
    bottom: 0;
		right: 0;
		transition-property: width,opacity;
    transition-delay: .9s;

    &::before,
    &::after {
      background: #9F8B49;
      position: absolute;
      width: 50%;
      height: 100%;
      bottom: 0;
    }

    &::before {
      left: 4px;
      transform: skewX(-45deg);

      @include mqPc {
        left: 9px;
      }
    }

    &::after {
      right: 4px;
      transform: skewX(45deg);

      @include mqPc {
        right: 9px;
      }
    }
	}
	/*&.is-fix{
		.c-animations--border__top{
			position: fixed;
		}
	}
	*/
}



.-vs{
	.p-animations--border {
		.c-animations--border__left {
			&::before,
			&::after {
				background: #E5004F;
			}
		}

		.c-animations--border__top {
			&::before,
			&::after {
				background: #F08300;
			}
		}

		.c-animations--border__right {
			&::before,
			&::after {
				background: #51318F;
			}
		}

		.c-animations--border__bottom {
			&::before,
			&::after {
				background: #9F8B49;
			}
		}
	}
}


.is-loaded {
  .p-animations--border {

    .c-animations--border__top,
    .c-animations--border__bottom {
			width: 100%;
			opacity: 1;
    }

    .c-animations--border__left,
    .c-animations--border__right {
			height: 100%;
			opacity: 1;
    }
  }
}

.vol28 {
  .p-animations--border {

    .c-animations--border__top,
    .c-animations--border__right,
    .c-animations--border__bottom,
    .c-animations--border__left {
			background: #E5004F;
      &::before,
      &::after {
        background: #E5004F;
      }
    }
  }
}

.vol29 {
  .p-animations--border {

    .c-animations--border__top,
    .c-animations--border__right,
    .c-animations--border__bottom,
    .c-animations--border__left {
			background: #F08300;
      &::before,
      &::after {
        background: #F08300;
      }
    }
  }
}

.vol30-31 {
  .p-animations--border {

    .c-animations--border__top,
    .c-animations--border__right,
    .c-animations--border__bottom,
    .c-animations--border__left {
			background: #51318F;
      &::before,
      &::after {
        background: #51318F;
      }
    }
  }
}


.is-fade {
  opacity: 0;
  transition: transform .6s;
  transform: translateY(100px);
}

.is-faded {
  opacity: 1;
  transform: translateY(0px);
}


@keyframes headborder {
  0% {
    border-width: 385px 270px 0 0;
	}

	15% {
		border-width: 385px 0px 0 0;
		border-color: transparent;
		height: 0;
		width: 0;
  }


	30% {
		border-width: 0;
		border-color: transparent;
		height: 0;
		width: 0;
	}

	40% {
		border-width: 0;
		border-color: transparent;
		height: 0;
		width: 0;
		position: absolute;
		left: 18px;
		right: 18px;
		height: 100%;
		left: 18px;
		right: 18px;
	}

	55% {
		border-width: none;
		border-color: transparent;
		height: 0;
		position: absolute;
		left: 18px;
		right: 18px;
		width: 0;
		max-width: calc(100% - 36px);
		background: rgba(255, 255, 255, 0.85);
		top: 18px;
		height: 100%;
		bottom: none;
	}


	100% {
		border-width: none;
		border-color: transparent;
		height: 0;
		position: absolute;
		left: 18px;
		top: 18px;
		right: 18px;
		bottom: 0;
		height: calc(100% - 18px);
		width: 100%;
		max-width: calc(100% - 36px);
		background: rgba(255, 255, 255, 0.85);
		bottom: none;
	}


}

@keyframes headborderSP {
  0% {
    border-width: 50vw 35.33vw 0 0;
  }

  15% {
		border-width: 50vw 0vw 0 0;
		background: none;
		border-color: transparent;
	}
	30% {
		border-width: 25vw 0vw 0 0;
		background: none;
		border-color: transparent;
	}

	30% {
		border-width: none;
		background: none;
		border-color: transparent;
		content: "";
		display: block;
		width: 0;
		height: calc(100% - 9px);
		position: absolute;
		left: 9px;
		right: 9px;
		top: 9px;
		background: rgba(255, 255, 255, 0.85);
	}

	50% {
		border-width: 25vw 0vw 0 0;
		background: none;
		border-color: transparent;
		content: "";
		display: block;
		width: 0;
		height: calc(100% - 9px);
		position: absolute;
		left: 9px;
		top: 9px;
		max-width: 100%;
		background: rgba(255, 255, 255, 0.85);
	}

	100% {
		border-width: 0;
		border-color: transparent;
		position: absolute;
		width: calc(100% - 18px);
		height: calc(100% - 9px);
		left: 9px;
		right: 9px;
		top: 9px;
		bottom: -9px;
		max-width: 100%;
		background: rgba(255, 255, 255, 0.85);
  }
}
