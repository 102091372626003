.vol28 {
  .c-related-events__name {
    color: #E5004F;
  }
}

.vol29 {
  .c-related-events__name {
    color: #F08300;
  }
}

.vol30-31 {
  .c-related-events__name {
    color: #51318F;
  }
}
