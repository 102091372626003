.p-archive-links{
	z-index: 100;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 5px 10px;
	background: #00b4ff;
	color: #fff;
	font-size: 1.3rem;
	line-height: 1.4;
	text-align: center;
	@include mqPc {
		font-size: 1.4rem;
		line-height: 1.7;
	}
	a{
		display: inline-block;
		text-decoration: underline;
		color: #fff;
	}
}
