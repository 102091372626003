
.c-about__contents{
	margin-top: fslh-spacing(11.5px,1.5rem,1.43);
	@include mqPc {
		margin-top: fslh-spacing(25px,2.4rem,1.625);
	}
	&.-highlight{
    padding: 14px 14px;
    border: 1px solid #9f8b49;
    margin-top: 20px;
		@include mqPc {
			padding: 22px 30px;
		}
		&+.c-about__contents{
			margin-top : 24px;
				@include mqPc {
					margin-top : 32px;
				}
		}
		.c-about__text{
			margin-top: 4px;
			@include mqPc {
				margin-top: 7px;
			}
		}
	}
}

.c-about__text-heading{
	font-weight: 600;
}

.c-about__heading{
	font-size: 1.5rem;
	line-height: 1.43;
	color: #9f8b49;
	font-weight: 600;
	@include mqPc {
		font-size: 2.4rem;
		line-height: 1.625;
	}
	&+.c-about__text{
		margin-top: fslh-spacing(12.5px,1.2rem,1.75);
		@include mqPc {
			margin-top: fslh-spacing(20px,1.6rem,1.87);
		}
	}
}
.c-about__text{
	font-size: 1.2rem;
	line-height: 1.75;
	font-weight: 300;
	letter-spacing: -.089em;
	position: relative;
	text-align: justify;
	@include mqPc {
		letter-spacing: -.099em;
		font-size: 1.6rem;
		line-height: 1.87;
	}
	.c-name{
		display: block;
		text-align: right;
	}
}

.c-about__special{
	font-weight: bold;
	text-align: center;
	padding: 7.5px 15px;
	border: 1px solid #9f8b49;
	line-height: 1.6;
	margin-top: 45px;
	@include mqPc {
    margin-top: 40px;
    padding: 17.5px 17px;
    line-height: 1.4;
    text-align: left;
	}
	p{
		font-size: 1.3rem;
		letter-spacing: normal;
		@include mqPc {
			font-size: 2rem;
		}
		span{
			font-size: 1.2rem;
			@include mqPc {
				font-size: 1.8rem;
			}
		}
	}
	a{
		color: #9f8b49;
		text-decoration: underline;
		@include mqPc {
		}
	}
	.u-note{
		font-size: 1.2rem;
		font-weight: normal;
		line-height: 2;
		@include mqPc {
			font-size: 1.4rem;
		}
	}
}


