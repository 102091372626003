.p-lead {
}

.c-lead__title {
	color: #9f8b49;
	font-size: 4.375vw;
	line-height: 1.5;
	text-align: center;
	letter-spacing: -.08em;
  padding-top: fslh-spacing(20px, 1.4rem, 1.7);
	-moz-osx-font-smoothing: inherit;
	-webkit-font-smoothing: subpixel-antialiased;
  @include mqPc {
		font-size: 2.6rem;
		letter-spacing: -.05em;
    line-height: 1.53;
    padding: 0 10px;
		padding-top: 0;
  }
}

.-is-21vol02{
	.c-lead__title {
		font-size: 4.063vw;
		@include mqPc {
			font-size: 2.6rem;
		}
	}
}

.p-lead--cancellation{
	color: #E60012;
	text-align: center;
	margin-top: 15px;
	line-height: 1.4;
	@include mqPc {
		letter-spacing: -.04em;
	}
}
.p-lead--cancellation__head{
	font-size: 14px;
	font-weight: 700;
	@include mqPc {
		display: inline-block;
		font-size: 20px;
	}
}

.p-lead--cancellation__link{
	text-decoration: underline;
	font-size: 14px;
	font-weight: 700;
	color: #E60012;
	@include mqPc {
		display: inline-block;
		font-size: 20px;
	}
}
.p-lead--cancellation__text{
	font-size: 12px;
	font-weight: 400;
	margin-top: 2px;
	@include mqPc {
		font-size: 16px;
	}
}


.c-section__heading {
  position: relative;
  padding-right: 5.74%;
  padding-left: 15px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.46 30.43"><path fill="%239f8b49" d="M0 0v30.43L21.46 0H0z" /></svg>');
  background-position: left top;
  background-size: 12px 25px;
  display: flex;
  align-items: center;
  @include mqPc {
    padding-left: 40px;
		padding-right: 40px;
		background-size:18px 22px;
  }

  &::after {
    display: block;
    height: 1px;
    background: #9f8b49;
    content: "";
    flex: 1 0 auto;
    margin-left: 10px;
  }
}
.c-section__heading{
	&-deco{
		width: 10px;
		height: auto;
		margin-right: 4px;
		@include mqPc {
			width: 21.46px;
			margin-right: 18.4px;
		}
	}
	svg{
		fill: #9F8B49;
	}
	&-text{
		height: auto;
	}
	&--news{
		.c-section__heading-text{
			width: 59.5px;
			@include mqPc {
				width: 89.65px;
			}
		}
	}
	&--about{
		.c-section__heading-text{
			width: 76.5px;
			@include mqPc {
				width: 115.37px;
			}
		}
	}
	&--performers{
		.c-section__heading-text{
			width: 130.355px;
			@include mqPc {
				width: 196.27px;
			}
		}
	}
	&--program{
		svg{
			fill: #fff;
		}
		.c-section__heading-text{
			width: 109.965px;
			@include mqPc {
				width: 165.47px;
			}
		}
	}
	&--profile{
		.c-section__heading-text{
			width: 84.86px;
			@include mqPc {
				width: 127.83px;
			}
		}
	}
	&--tickets{
		.c-section__heading-text{
			width: 86.555px;

			@include mqPc {
				width: 130.31px;
			}
		}
	}
	&--movie{
		.c-section__heading-text{
			width: 78.07px;

			@include mqPc {
				width: 117.54px;
			}
		}
	}
}





.-hc{
	.c-section__heading {
		padding: 0;
		background: none;
		display: flex;
		position: relative;
		width: calc(100% - 13px);
		@include mqPc {
			width: calc(100% - 40px);
		}
		&::after{
			content: "";
		}
		&--news{
			img{
				width: 75px;
				@include mqPc {
					width: 129.14px;
				}
			}
		}

		&--about{
			img{
				width: 91.5px;
				@include mqPc {
					width: 154.7px;
				}
			}
		}

		&--performers{
			img{
				width: 145px;
				@include mqPc {
					width: 235.58px;
				}
			}
		}

		&--program{
			img{
				width: 125px;
				@include mqPc {
					width: 200.4px;
				}
			}
		}

		&--profile{
			padding: 0 0 7.5px 0;
			img{
				width: 100px;
				@include mqPc {
					width: 165px;
				}
			}
		}

		&--tickets{
			img{
				width: 101.5px;
				@include mqPc {
					width: 167.92px;
				}
			}
		}

		&--movie{
			img{
				width: 98px;
				@include mqPc {
					width: 156px;
				}
			}
		}
	}

}

.c-section__heading--news {
  .c-img__text {
    width: 27.3%;
    max-width: 126px;

    @include mqPc {
      width: 95px;
    }
  }
}

.c-section__heading--about {
  .c-img__text {
    width: 30.2%;
    max-width: 145px;

    @include mqPc {
      width: 111px;
    }
  }
}

.c-section__heading--performers {
  .c-img__text {
    width: 50%;
    max-width: 234px;

    @include mqPc {
      width: 203px;
    }
  }
}

.c-section__heading--program {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.46 30.43"><path fill="%23ffffff" d="M0 0v30.43L21.46 0H0z" /></svg>');

  .c-img__text {
    width: 44%;
    max-width: 195px;
    fill: #fff;

    @include mqPc {
      width: 156px;
    }
  }

  &::after {
    display: none;
  }
}

.c-section__heading--profile {
  .c-img__text {
    width: 44%;
    max-width: 170px;

    @include mqPc {
      width: 144px;
    }
  }
}

.c-section__heading--tickets {
  .c-img__text {
    width: 41%;
    max-width: 161px;

    @include mqPc {
      width: 133px;
    }
  }
}

.c-section__heading--movie {
  .c-img__text {
    width: 36.1%;
    max-width: 154px;
    @include mqPc {
      width: 124px;
    }
  }
}

.c-heading {
  color: #9F8B49;
}

.vol28 {
  .c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #E5004F;
    }
  }
}

.vol29 {
  .c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #F08300;
    }
  }
}

.vol30-31{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #51318F;
    }
  }
}

.-is-21vol01{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #00B1A9;
    }
  }
}
.-is-21vol02{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #EF8454;
    }
  }
}
.-is-21vol03{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #E5006E;
    }
  }
}
.-is-21vol04{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #6ABD79;
    }
  }
}
.-is-21vol05{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #54C3F1;
    }
  }
}
.-is-21vol06{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #F091A0;
    }
  }
}
.-is-21vol07{
	.c-section__heading--news,
  .c-section__heading--about,
  .c-section__heading--performers,
  .c-section__heading--profile,
  .c-section__heading--tickets,
  .c-section__heading--movie {
    svg {
      fill: #837FB6;
    }
  }
}

.-is-22vol01{
	.c-section__heading{
		&:not(.c-section__heading--program){
			.c-section__heading-text{
				fill: #683b93;
			}
		}
	}
}
.-is-22vol02{
	.c-section__heading{
		&:not(.c-section__heading--program){
			.c-section__heading-text{
				fill: #EF782A;
			}
		}
	}
}
.-is-22vol03{
	.c-section__heading{
		&:not(.c-section__heading--program){
			.c-section__heading-text{
				fill: #E73557;
			}
		}
	}
}
.-is-22vol04{
	.c-section__heading{
		&:not(.c-section__heading--program){
			.c-section__heading-text{
				fill: #00B2AA;
			}
		}
	}
}

.-is-22vol01,
.-is-22vol02,
.-is-22vol03,
.-is-22vol04{
	.c-tickets__label.-hc-color{
		color: #9f8b49;
	}
}

.c-page__title{
	text-align: center;
	padding: 17.5px 0 ;
	line-height: 1;
	@include mqPc {
		margin: -10px 0 0 0;
		padding: 33px 0;
	}
}

.access{
	.c-page__title{
		margin-top: -13.5px;
		@include mqPc {
			margin-top: -33px;
		}
		img{
			width: 74.5px;
			margin-left: auto;
			margin-right: auto;
			@include mqPc {
				width: 119px;
			}
		}
	}
}
.archive{
	.c-page__title{
		margin-top: -10px;
		padding: 18.5px 0 22.5px 0;
		@include mqPc {
			margin-top: -35px;
			padding: 34px 0 22.5px 0;
		}
		img{
			width: 91.5px;
			margin-left: auto;
			margin-right: auto;
			@include mqPc {
				width: 155px;
			}
		}
	}
}

.news{
	.c-page__title{
		border-bottom: 1px solid #9F8B49;
		svg{
			width: 20.92%;
			height: 27px;
			@include mqPc {
				width: 113px;
				height: 27px;
			}
		}
	}
}




