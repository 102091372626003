.c-release{
	position: relative;
	padding-bottom: 15px;
	-moz-osx-font-smoothing: inherit;
	-webkit-font-smoothing: subpixel-antialiased;
	@include mqPc {
		border: 1px solid #9F8B49;
		padding: 12px 15px 29px 15px;
	}
	&::before,
	&::after{
		content: "";
		height: 5px;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		border-top: 1px solid #9F8B49;
		border-bottom: 1px solid #9F8B49;
		@include mqPc {
			display: none;
		}
	}
	&::before{
		top: 0;
	}
	&::after{
		bottom: 0;
	}
}
.c-release__header{
	text-align: center;
	p{
		color: #F08300;
		font-size: 1.4rem;
		line-height: 1.35;
		padding: fslh-spacing(7px, 1.4rem, 1.35) 0;
		@include mqPc {
			font-size: 2.8rem;
			line-height: 1.35;
			padding: fslh-spacing(15px, 2.8rem, 1.35) 0;
		}
	}
}
.c-release__name{
	position: relative;
	font-size: 1.7rem;
	line-height: 1.58;
	padding: fslh-spacing(13px, 1.7rem, 1.58) 0;

	@include mqPc {
		padding-top: 0;
		padding-bottom: 9px;
		font-size: 3.4rem;
		line-height: 1.58;
		letter-spacing: -.103em;
	}
	.heading{
		@include mqPc {
			display: inline-block;
		}
	}
	&::after{
		content: "";
		height: 5px;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		border-top: 1px solid #9F8B49;
		border-bottom: 1px solid #9F8B49;
		@include mqPc {
			height: 7.5px;
		}
	}

	&::after{
		bottom: 0;
	}
}
.c-release__heading{
	color: #fff;
	font-size: 1.2rem;
	line-height: 1.2;
	padding: fslh-spacing(5px, 1.2rem, 1.2) 5px;
	position: relative;
	width: calc(100% - 10px);
	background: #9F8B49;
	margin-left: auto;
	margin-right: auto;
	@include mqPc {
    font-size: 2.2rem;
		line-height: 1.72;
		padding: 0 3px;
		width: calc(100% - 30px);
		margin: 0 auto;
		letter-spacing: -.03em;
	}
	&::before,
	&::after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 5px;
		height: 100%;
		background-size: 5px 100%;
		@include mqPc {
			width: 10px;
			background-size: 10px 100%;
		}
	}
	&::before{
		left: -5px;
		background-image: url(./../images/heading_background_02--sp_left.png);
		@include mqPc {
			left: -10px;
			background-image: url(./../images/heading_background_02--pc_left.png);
		}
	}
	&::after{
		right: -5px;
		background-image: url(./../images/heading_background_02--sp_right.png);
		@include mqPc {
			right: -10px;
			background-image: url(./../images/heading_background_02--pc_right.png);
		}
	}

}
.c-release__contents{
	@include mqPc {
		order: 1;
		display: flex;
		margin-top: 25px;
	}
}
.c-release__image{
	margin-top: 10px;
	@include mqPc {
		margin-top: 0;
		order: 2;
		flex: 0 1 270px;
		max-width: 270px;
	}
	img{

	}
}
.c-release__text{
	font-size: 1.2rem;
	line-height: 1.66;
	text-align: justify;
	margin: fslh-spacing(10px, 1.2rem, 1.66) 0 0;
	@include mqPc {
		padding-right: 15px;
		font-size: 2rem;
		line-height: 1.5;
		margin-top: 0;
		flex: 0 1 490px;
		max-width: 490px;
		letter-spacing: -.051em;
	}
	p{
	}
	small{
		font-size: 1.1rem;
		line-height: 1.6;
		font-weight: 300;
		margin: fslh-spacing(7px, 1.1rem, 1.6) 0 0;
		@include mqPc {
			font-size: 1.6rem;
			line-height: 1.8;
		}
	}
}
.c-release__logo{
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	@include mqPc {
		margin-top: 20px;
		justify-content: flex-start;
	}
	li{
		margin: 0 17px;
		&:nth-of-type(1){
			width: 12.9%;
			@include mqPc {
				width: 52px;
			}
		}
		&:nth-of-type(2){
			width: 41.25%;
			@include mqPc {
				width: 166px;
			}
		}
	}
}

.c-relatedEevents{
	&+.c-release{
		margin-top: 50px;
	}
}
