

.access{
	.c-map{
		iframe{
			width: 100%;
			height: 288px;
			padding-bottom: 15px;
			@include mqPc {
				height: 575px;
				padding-bottom: 15px;
			}
		}
	}


	.c-media__images{
		flex: 1 0 100%;
		@include mqPc {
		order: 1;
		flex: 1 1 54.625%;
		max-width: 363px;
		}
	}
	.c-media__contents{
		flex: 1 0 100%;
		@include mqPc {
		order: 2;
		flex: 1 1 calc(48.75% - 40px);
		padding-left: 40px;
		max-width: 437px;
		}
		&+.c-media__images{
			margin-top: 20px;
			@include mqPc {
				margin-top: 0;
			}
		}
	}

	.c-list--dots{
		&+.c-list--dots{
			margin-top: 22.5px;
			@include mqPc {
				margin-top: 23px;
			}
		}
	}
	.c-list__item{
		padding-left: 12.5px;
		position: relative;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		font-size: 1.2rem;
		line-height: 1.5;
		font-weight: 300;
		@include mqPc {
			padding-left: 17px;
			line-height: 1.7;
			font-size: 1.6rem;
			letter-spacing: -.097em;
		}
		&:nth-of-type(n+2){
			margin-top: 7.5px;
			@include mqPc {
				margin-top: 6px;
			}
		}
		&::before{
			display: inline-block;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: #9f8b49;
			content: "";
			position: absolute;
			left: 3px;
			top: 5.5px;
			@include mqPc {
				width: 13px;
				height: 13px;
				left: 0;
			}
		}
		.c-list--dots{
			.c-list__item{
				&::before{
					top: 6.5px;
					left: 3px;
					width: 3px;
					height: 3px;
					@include mqPc {
						left: 0;
						top: 10px;
						width: 6px;
						height: 6px;
					}
				}
			}
		}
	}
	.c-list__label{
		flex: 1 0 100%;
		font-weight: bold;
		@include mqPc {
			letter-spacing: 0em;
		}
	}
	.c-list__text{
		flex: 1 0 100%;
		@include mqPc {
			letter-spacing: 0em;
		}
	}


	.c-buttons{
			font-size: 1.3rem;
			padding-bottom: 10px;
			@include mqPc {
				justify-content: space-between;
				margin-left: -15px;
				margin-right: -15px;
				padding-bottom: 25px;
			}
		.c-button--bg{
			padding: 6px 3px;
			display: block;
			text-align: center;
			color: #9f8b49;
			border: 1px solid #9f8b49;
			margin: 8px 15px 0px;
			@include mqPc {
				font-size: 1.6rem;
				flex: 1 0 300px;
				border: 1px solid #9f8b49;
				padding: 6px 5px;
			}
			svg{
				width: 6px;
				height: 8px;
				fill: #9f8b49;
				margin-left: 7.5px;
				@include mqPc {
					margin-left: 10px;
				}
			}
			&:nth-of-type(n+2){
				margin-top: 40px;
				@include mqPc {
					margin-top: 8px;
				}
			}
		}
	}
	.c-media__ttl{
		border: 1px solid #9f8b49;
		color: #9f8b49;
		text-align: center;
		background: url(../images/heading_img_icon_accesee.svg) no-repeat 5px calc(100% - 7.5px);
		background-size: 25px 24px;
		padding: 15px 25px;
		font-size: 1.5rem;
		@include mqPc {
			padding: 14.5px 15px;
			background-size: 34px 32px;
			background: url(../images/heading_img_icon_accesee.svg) no-repeat 15px calc(100% - 15px);
			font-size: 2.2rem;
			line-height: 1.6;
		}
	}
	.c-media__text{
		font-size: 12.5px;
		margin-top: 15px;
		font-weight: normal;
		@include mqPc {
			font-size:1.8rem;
		}
	}
	.c-buttons{
		@include mqPc {
			margin-left: 0;
			margin-right: 0;
			padding-left: 10px;
			padding-right: 10px;
		}
		.c-button--bg{
			margin-left: 0;
			margin-right: 0;
			@include mqPc {
				margin-left: 40px;
				margin-right: 40px;
			}
		}
	}
	.c-buttons{
		margin-top: 37.5px;
		@include mqPc {
			margin-top: 60px;
		}
	}
	.c-media--access{
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		@include mqPc {
			align-items: center;
			margin-top: 37px;
		}
	}
	.c-media__contents{
		@include mqPc {
			order: 1;
			flex: 1 0 430px;
			max-width: 430px;
			padding-left: 0;
			padding-right: 40px;
		}
	}
	.c-media__images{
		@include mqPc {
			order: 2;
			flex: 1 1 calc(100% - 430px);
			max-width: calc(100% - 430px);
		}
	}

}
