.c-buttons{
	@include mqPc {
		padding: 0 40px;
		display: flex;
		flex-wrap: wrap;
		margin-top: fslh-spacing(45px,2.6rem,1.53);
	}
	.c-button{
		-moz-osx-font-smoothing: inherit;
		-webkit-font-smoothing: subpixel-antialiased;
		color: #fff;
		display: block;
		width: 75%;
		margin: 0 auto;
		text-align: center;
		padding: 6px 5px;
		position: relative;
		font-size: 1.6rem;
		line-height: 1.7;
		font-weight: 700;
		overflow-x: hidden;
		flex: 1 0 75%;
		max-width:75%;
		@include mqPc {
			width: 100%;
			max-width: calc(50% - 30px);
			flex: 1 1 calc(50% - 30px);
			min-width: calc(50% - 30px);
			font-size: 2.4rem;
			line-height: 1.6;
			padding: fslh-spacing(13px,2.4rem,1.6);
		}
		svg{
			width: 6px;
			height: 9px;
			fill: #fff;
			position: relative;
			z-index: 10;
			margin-left: 5px;
			@include mqPc {
				width: 7px;
			height: 12px;
			}
		}
		&::before {
			content: "";
			background: #666;
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		&::after {
			content: "";
			background: #000;
			position: absolute;
			display: block;
			top: 0;
			left: 15px;
			width: calc(100% - 30px);
			height: 100%;
			transform: skewX(-30deg);
	}
		&:nth-of-type(n+2){
			margin: 31px auto 0 auto;
			@include mqPc {
				margin-top: 0;
			}
		}
		&:nth-of-type(even){
			@include mqPc {
			margin-right: 0;
			}
		}
		&:nth-of-type(odd){
			@include mqPc {
			margin-left: 0;
			}
		}
	}
	.c-button__inner {
		position: relative;
    z-index: 1;
	}
	a{
		text-decoration: none;
	}
	&.c-button--sold{
		//margin-bottom: -35px;
		@include mqPc {
			//margin-bottom: -51px;
		}
		.c-button{
			pointer-events: none;
			&::before{
				background: #9fa0a0;
			}
			&::after{
				background: #727171;
			}
		}
		.c-sold__text{
			flex: 1 0 85%;
			text-align: center;
			max-width:85%;
			color: #C40018;
			font-size: 1.4rem;
			margin-top: 15px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1.7;
			@include mqPc {
				font-size: 2.0rem;
				margin-top: 20px;
			}
		}
		.c-sold__text__small{
			font-size: 1.2rem;
			color: #C40018;
			font-weight: 400;
			flex: 1 0 85%;
			max-width:85%;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			@include mqPc {
				font-size: 1.6rem;
			}
		}
	}
}

.vol28{
	.c-buttons{
		.c-button{

		}
		.c-button__inner {
		}
	}
}

.vol28{
	.c-button{
		&::before{
			background: #bd4744;
		}
		&::after{
			background: #af192f;
		}
	}
}

.vol29{
	.c-button{
		&::before{
			background: #ea662f;
		}
		&::after{
			background: #E94709;
		}
	}
}

.vol30-31{
	.c-button{
		&::before{
			background: #634782;
		}
		&::after{
			background: #3c276d;
		}
	}
}

.c-lead__title{
	&+.c-buttons{
		margin-top: 21px;
		@include mqPc {
			margin-top: 42px;
		}
	}
}


.-is-21vol01{
	.c-button{
		&::before{
			background:rgba(0,177,169,0.5);
		}
		&::after{
			background: #00B1A9;
		}
	}
}
.-is-21vol02{
	.c-button{
		&::before{
			background-color:rgba(239,132,84,0.5);
		}
		&::after{
			background:#EF8454;
		}
	}
}
.-is-21vol03{
	.c-button{
		&::before{
			background-color:#f19db4;
		}
		&::after{
			background: #E5006E;
		}
	}
}
.-is-21vol04{
	.c-button{
		&::before{
			background-color:rgba(106,189,121,0.5);
		}
		&::after{
			background: #6ABD79;
		}
	}
}
.-is-21vol05{
	.c-button{
		&::before{
			background-color:rgba(84,195,241,0.5);
		}
		&::after{
			background: #54C3F1;
		}
	}
}
.-is-21vol06{
	.c-button{
		&::before{
			background-color:rgba(240,145,160,0.5);
		}
		&::after{
			background: #F091A0;
		}
	}
}
.-is-21vol07{
	.c-button{
		&::before{
			background-color: #683B93;
		}
		&::after{
			background: #837FB6;
		}
	}
}

.archive{
	.c-buttons{
		padding-bottom: 20px;
		@include mqPc {
			margin-top: 0;
		}
		.c-button--bg{
			width: 185px;
			margin-top: 50px;
			margin-left: auto;
			margin-right: auto;
			@include mqPc {
				margin-top: 60px;
				flex: 0 1 385px;
				width: 385px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

