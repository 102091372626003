@import "./../foundation/variables";
@import "./../foundation/mixin";

.is-loaded{
	.l-header{
		opacity: 1;
			.p-header__logo {
			}

			&::before {
				opacity: 1;
			}
			.p-header__toggle {
			}
			.p-header__navigation {
				@include mqPc {
					opacity: 1;
				}
			}
	}
}

.l-header {
	opacity: 0;
	transition-delay: .6s;
	transition: opacity 1s;
  position: sticky;
  top: 0;
	z-index: 10;
	margin-bottom: 6px;

  @include mqPc {
		margin-bottom: 0;
	}

  &.is-move {
		.l-header__inner{
			@include mqPc {
				padding: 36px 0 46px 0;
			}
		}
    .p-header__logo svg {
      fill: #000;
    }

    &::before {
      display: block;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-width: 0vw 35.33vw 0 0;
      animation: headborderSP ease-in .5s forwards;

      @include mqPc {
        animation: headborder ease-in .5s forwards;
      }
		}

		.p-header__logo{
			@media screen and (min-width: 480px) and (max-width: 768px){

			}
		}

		.p-header__navigation{
			@include mqTb {
				margin-top: -30.5px;
			}
		}

  }

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 190px 130px 0 0;
    border-color: #9F8B49 transparent transparent transparent;
    position: absolute;
    top: 9px;
    left: 9px;
    z-index: 0;
    opacity: 0;
		transition: opacity 1s ease-in,border-width .3s ease-in;

		@media screen and (min-width: 480px) and (max-width: 768px){
			border-width: 235px 185px 0 0;
		}

    @include mqPc {
      border-width: 360px 255px 0 0;
      max-width: 272px;
      top: 18px;
      left: 18px;
    }
  }


  &.is-open {
    z-index: 100;

    .p-header__navigation {
      display: block;
      position: absolute;
			left: 2.5px;
			right: auto;
			width: calc(100% - 5px);
			max-width: calc(100% - 5px);
      border-right: 1px solid #9f8b49;
      border-bottom: 1px solid #9f8b49;
			border-left: 1px solid #9f8b49;
			border-top: none;
			background: #fff;
			z-index: 10;
    }

    .p-header__contents {
      background: #fff;
      border: 1px solid #9F8B49;
      z-index: 20;

      &::before {
        border-right: 40px solid transparent;
        border-top: 60px solid #9f8b49;
        display: block;
        content: "";
				position: absolute;
				@media screen and (min-width: 480px) and (max-width: 768px){
					border-top: 95px solid #9f8b49;
					border-right: 60px solid transparent;
				}
      }
    }

    .p-header__toggle {

      span {
        &:nth-of-type(1) {
          transform: rotate(-45deg);
					top: 15px;
					@media screen and (min-width: 480px) and (max-width: 768px){
						top: 23px;
					}
        }

        &:nth-of-type(2) {
          transform: translateX(200%);
        }

        &:nth-of-type(3) {
          transform: rotate(45deg);
					bottom: 15px;
					@media screen and (min-width: 480px) and (max-width: 768px){
						bottom: 23px;
					}
        }
      }
    }
  }
}

.l-header__inner {
  width: calc(100% - 13px);
  position: relative;
  padding: 9px 0 0 0;
  margin-left: auto;
  margin-right: auto;

  @include mqPc {
    width: calc(100% - 36px);
    padding: 36px 0 18px 0;
  }
}

.p-header__logo {
	width: 80px;
	height: 40px;
  position: absolute;
  z-index: 10;
	top: 19px;
	left: 9px;
	transition: height .3s ease-in,width .3s ease-in;

	@media screen and (min-width: 480px) and (max-width: 768px){
		width: 140px;
    height: 70px;
	}

  @include mqPc {
    width: 107px;
    top: 45px;
    left: 50px;
  }

  svg {
    width: 100%;
		max-width: 160px;
		min-width: 80px;
    height: 100%;
		max-height: 80px;
		min-height: 40px;
    fill: #fff;
    transition: fill .3s;

    @include mqPc {
      width: 105px;
      height: 59px;
    }
  }
}

.p-header__contents {
  width: calc(100% - 5px);
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include mqPc {
    padding-bottom: 20px;
    margin-top: 10px;
  }
}

.p-header__toggle {
	display: block;
	position: relative;
	right: 0;
	width: 60px;
	margin: 0 0 0 auto;
	padding: 15px 11px;
	overflow-x: hidden;

	@media screen and (min-width: 480px) and (max-width: 768px){
		width: 120px;
		padding: 20px 30px;
	}

  @include mqPc {
    display: none;
  }

  span {
    display: block;
    background: #5F5F5F;
    width: 100%;
    height: 3px;
    margin: 0 0 12px 0;
    transition: transform .5s;
		position: relative;
		@media screen and (min-width: 480px) and (max-width: 768px){
			margin: 0 0 20px 0;
			height: 4px;
		}

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
}

.p-header__navigation {
  width: 100%;
  margin: 0 auto;
  display: none;
	border-top: 1px solid #9F8B49;

  @include mqPc {
    border-top: none;
    width: 100%;
		padding: 0 12% 0 21%;
    background: none;
		display: block;
		opacity: 0;
		transition: opacity .9s;
	}

	@media screen and (max-width: 1250px){
		padding-right: 0;
	}

	@media screen and (max-width: 1100px){
		padding-left: 19%;
	}

	@media screen and (max-width: 1024px){
		padding-left: 30%;
	}

	@media screen and (max-width: 768px){
		padding-left: 0;
	}


}

.p-header__navigation {

}

.p-header__navigation__list {
  @include mqPc {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	@media screen and (max-width: 1150px){
		justify-content: flex-end;
	}
	@media screen and (max-width: 1024px){
		flex-wrap: wrap;
		margin-top: -16px;
	}
	@media screen and (max-width: 768px){
		margin-top: -1px;
		justify-content: flex-start;
	}
}

.p-header__navigation__item {
	border-bottom: 1px solid #9F8B49;
	margin: 0;

  @include mqPc {
    border-bottom: none;
    border-right: 1px solid #9F8B49;
	}
	@media screen and (max-width: 1100px){
    flex: 0 1 auto;
    min-width: auto;
		text-align: right;
	}

	@media screen and (max-width: 1024px){
		margin: 6px 0;
		&:nth-of-type(3){
			border-right: none;
		}
	}

	@media screen and (max-width: 768px){
		margin: 0;
		text-align: left;
	}


  &:nth-last-of-type(1) {
    border-bottom: none;
  }

  &:nth-last-of-type(3) {
    @include mqPc {
			border-right: none
    }
	}

	@include mqPc {
		&:hover{
			svg{
				fill: #9f8b49;
			}
		}
	}

  svg {
    height: 17px;

    @include mqPc {
      height: 19px;
    }
  }

  &.is-current {
    background: #e7e2cf;

    @include mqPc {
      background: none;
    }

    svg {
      fill: #9F8B49;
    }
  }

  @include mqPc {
    border-bottom: none;
  }

  &.text--top {
    svg {
      width: 64px;
    }

    @include mqPc {}
  }

  &.text--vol28 {
    svg {
      width: 197px;

      @include mqPc {
        width: 164px;
      }
    }

  }

  &.text--vol29 {
    svg {
      width: 265px;

      @include mqPc {
        width: 225px;
      }
    }

  }

  &.text--vol30-31 {
    svg {
      width: 255px;

      @include mqPc {
        width: 208px;
      }
    }

  }

  &.text--access {
    svg {
      width: 106px;

      @include mqPc {
        width: 79px;
      }
    }

  }

  &.text--ongakudo {
    svg {
      width: 145px;
    }

    @include mqPc {}
	}
	@include mqPc {}

	@media screen and (max-width: 1024px){
		&:nth-of-type(3),
		&:nth-of-type(5){
			border-right: none;
		}
	}
}

.p-header__navigation__link {
  padding: 22px 15px;
  display: block;
  line-height: 1;

  @include mqPc {
    padding: 3px 15px;
	}

	@include mqTb {
		padding: 3px 30px;
	}


  .c-svg {
    @include mqPc {
      height: 9px;
      width: 6px;
    }
  }
}


.vol28 {
  .l-header {
    &::before {
      border-color: #E5004F transparent transparent transparent;
    }

    &.is-move {
      &::before {
        background: #E5004F;
      }

      .p-header__logo {
        svg {
          fill: #E5004F;
        }
      }
    }
  }
}

.vol29 {
  .l-header {
    &::before {
      border-color: #F08300 transparent transparent transparent;
    }

    &.is-move {
      &::before {
        background: #F08300;
      }

      .p-header__logo {
        svg {
          fill: #F08300;
        }
      }
    }
  }
}

.vol30-31 {
  .l-header {
    &::before {
      border-color: #51318F transparent transparent transparent;
    }

    &.is-move {
      &::before {
        background: #51318F;
      }

      .p-header__logo {
        svg {
          fill: #51318F;
        }
      }
    }
  }
}
.u-header__navigation__item--info{
	border-right: none;
	font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, sans-serif;
	font-weight: bold;
	font-size: 15px;
	@include mqPc {
		margin-top: 15px;
		padding-right: 15px;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		flex: 1 1 100%;
		font-size: 14px;
	}
	.p-header__navigation__link{
		text-decoration: none;
		@include mqPc {
			display: block;
			padding: 5px 16px;
			border-radius: 15px;
			background: #9F8B49;
			color: #fff;
		}
		svg{
			width: 7px;
			height: 10px;
			fill: #000;
			@include mqPc {
				fill: #fff;
				width: 6px;
				height: 8px;
				position: relative;
				top: -1px;
				left: 2px;
			}
		}
	}
}




.hc-l-header{
	position: sticky;
	top: 0;
	z-index: 1;
	padding: 22.5px 25px 30px 25px;
	@include mqPc {
		padding: 40px 18px;
	}
	&.is-open{
		z-index: 100;
		.hc-p-header-navigation-container{
			@include mqPc {
			}
		}
		.hc-p-header-navigation__toggle{
			top: 10px;
			right: 20px;
			@include mqPc {
				top: 20px;
			}
			span{
				opacity: 0;
			}
			&::before{
				transform: rotate(45deg);
				top: calc(50% + 10px);
				@include mqPc {
					top: 50%;
				}
			}
			&::after{
				transform: rotate(-45deg);
				bottom: calc(50% - 10px);
				@include mqPc {
					bottom: 50%;
				}
			}
		}
		.hc-p-header-navigation{
			border-top: 1px solid #9f8b49;
			border-right: 1px solid #9f8b49;
			border-left: 1px solid #9f8b49;
			background: #fff;
			transition: background .3s ease-in;
			padding-top: 52.5px;
			z-index: 60;
			overflow-y: scroll;
			height: calc(100% - 12px);
			@include mqPc {
				padding-top: 60px;
				height: 100%;
				transform: translateX(0%);
				transition: transform .3s ease-in;
			}
			.hc-p-header-navigation-container{
				visibility: visible;
				opacity: 1;
				position: relative;
				z-index: 10;
				transition: opacity .3s ease-in;
				height: auto;
				border-top: 1px solid #9f8b49;
			}
		}
		.hc-p-header-overlay{
			display: block;
			position: fixed;
			width: calc(100% - 18px);
			height: calc(100% - 9px);
			top: 9px;
			left: 9px;
			background-color:rgba(35,24,21,0.2);
			z-index: 0;
			@include mqPc {
				width: calc(100% - 36px);
				height: calc(100% - 18px);
				top: 18px;
				left: 18px;
			}
		}
	}
	&.is-move{
		&::after{
			display: block;
			z-index: -1;
			position: absolute;
			top: 10px;
			left: 9px;
			width: calc(100% - 18px);
			height: calc(100% - 18px);
			content: "";
			background: #ffffff80;
			@include mqPc {
				top: 18px;
				left: 18px;
				width: calc(100% - 36px);
				height: calc(100% - 36px);
			}
		}
}
}

.hc-p-header{
	&-logo{
		width: 105px;
		@include mqPc {
			width: 523px;
			padding: 0 25px;
		}
	}
	&-navigation{
		position: fixed;
		top: 12.5px;
		left: 13px;
		width: calc(100% - 26px);
		z-index: 20;
		@include mqPc {
			top: 18px;
			right: 18px;
			left: auto;
			width: 350px;
			transform: translateX(100%);
			transition: transform .3s ease-in;
		}
		&__toggle{
			position: fixed;
			right: 15px;
			top: 15px;
			width: 45px;
			height: 40px;
			padding: 5px;
			z-index: 61;
			@include mqPc {
				width: 53px;
				height: 53px;
				padding: 10px;
				right: 25px;
				top: 25px;
				cursor: pointer;
			}
			&::before,
			&::after{
				content: "";
				display: block;
				width: 35px;
				height: 2px;
				background: #666;
				position: absolute;
				@include mqPc {
					width: 33px;
					height: 2px;
				}
			}
			&::before{
				top: 5px;
				@media screen and (min-width: 480px) and (max-width: 768px){
				}
				@include mqPc {
					top: 10px;
				}
			}
			&::after{
				bottom: 5px;
				@media screen and (min-width: 480px) and (max-width: 768px){
				}
				@include mqPc {
					bottom: 10px;
				}
			}
			span{
				width: 35px;
				height: 2px;
				background: #666;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				@media screen and (min-width: 480px) and (max-width: 768px){
				}
				@include mqPc {
					width: 33px;
					height: 2px;
				}
			}
		}
		&-container{
			visibility: hidden;
			opacity: 0;
			position: relative;
			z-index: -1;
			height: 0;
			@include mqPc {
				height: auto;
				opacity: 1;
				z-index: 1;
			}
		}
		&__item{
			border-bottom: 1px solid #9f8b49;
			svg{
				width: 7.5px;
				height: 10.5px;
				margin-left: 10px;
				@include mqPc {
					width: 8.5px;
					height: 10.5px;
				}
			}
			&--top{
				img{
					width: 43px;
					@include mqPc {
						width: 41px;
					}
				}
			}
			&--vol1{
				img{
					width: 188px;
					@include mqPc {
						width: 180px;
					}
				}
			}
			&--vol2{
				img{
					width: 222.5px;
					@include mqPc {
						width: 213.3px;
					}
				}
			}
			&--vol3{
				img{
					width: 244.5px;
					@include mqPc {
						width: 301px
					}
				}
			}
			&--vol4{
				img{
					width: 248.5px;
					@include mqPc {
						width: 237px;
					}
				}
			}
			&--vol5{
				img{
					width: 137px;
					@include mqPc {
						width: 130px;
					}
				}
			}
			&--vol6{
				img{
					width: 202.5px;
					@include mqPc {
						width: 194px;
					}
				}
			}
			&--vol7{
				img{
					width: 203.5px;
					@include mqPc {
						width: 194px;
					}
				}
			}
			&--access{
				img{
					width: 71.5px;
					@include mqPc {
						width: 68px;
					}
				}
			}
			&--ongakudo{
				img{
					width: 125px;
					@include mqPc {
						width: 118px;
					}
				}
			}
			&--archive{
				img{
					width: 82.5px;
					@include mqPc {
						width: 68px;
					}
				}
			}
			&.is-current{
				background: #f5f2eb;
			}
			@include mqPc {
				&:hover{
					background: #f5f2eb;
				}
			}
		}
		&__link{
			padding: 20px 12.5px;
			display: flex;
			align-items: center;
			text-decoration: none;
			@include mqPc {
				padding: 20px 10px;
			}
		}

	}
	&-overlay{
		display: none;
	}
}

