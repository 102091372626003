.p-archive{
	&__text{
		font-size: 1.4rem;
		font-weight: 400;
		@include mqPc {
			font-size: 1.8rem;
			margin-top: 20px
		}
	}
	&__heading{
		font-size: 1.4rem;
		font-weight: 600;
		color: #9f8b49;
		margin-top: 45px;
		@include mqPc {
			font-size: 2rem;
		}
		&+ .p-archive-list{
			margin-top: 7.5px;
		}
	}
	&-list{
		font-size: 1.4rem;
		font-weight: 600;
		margin-top: 5px;
		@include mqPc {
			margin-top: 10px;
			font-size: 1.8rem;
		}
		&__text{
			&--note{
				font-size: 1.2rem;
				font-weight: 300;
				display: block;
				line-height: 1.2;
				text-decoration: none;
				letter-spacing: -.07em;
				@include mqPc {
					display: inline;
				}
			}
		}
		&__item{
			letter-spacing: -.04em;
			@include mqPc {
				display: flex;
			}
			&+.p-archive-list__item{
				margin-top: 7.5px;
				@include mqPc {
					margin-top: 10px;
				}
			}
		}
		&__label{
			color: #9f8b49;
			display: block;
			@include mqPc {
				display: inline-block;
				min-width: 110px;
			}
		}
		&__link{
			display: inline-block;
		}
		&__name{
			&--jp{
				font-size: 1.4rem;
				letter-spacing: -.05em;
			}
		}
		a{
			&.p-archive-list__link{

			}
			.p-archive-list__name--jp{
				text-decoration: underline;
			}
		}
	}
}

.archive{
	.c-buttons{
		font-size: 1.3rem;
		@include mqPc {
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
		}
	.c-button--bg{
		padding: 6px 3px;
		display: block;
		text-align: center;
		color: #9f8b49;
		border: 1px solid #9f8b49;
		margin: 8px 15px 0px;
		@include mqPc {
			font-size: 1.6rem;
			flex: 0 1 385px;
			width: 385px;
			border: 1px solid #9f8b49;
			padding: 6px 5px;
		}
		svg{
			width: 6px;
		height: 9px;
		fill: #9f8b49;
		margin-left: 3px;
		}
		&:nth-of-type(n+2){
			margin-top: 40px;
			@include mqPc {
				margin-top: 8px;
			}
		}
	}
}
.c-buttons{
	@include mqPc {
		margin-left: auto;
		margin-right: auto;
		padding-left: 10px;
		padding-right: 10px;
	}
	.c-button--bg{
		margin-left: auto;
		margin-right: auto;
		@include mqPc {
			margin-left: 40px;
			margin-right: 40px;
		}
	}
}
.c-buttons{
	margin-top: 37.5px;
	@include mqPc {
		margin-top: 60px;
		justify-content: center;
	}
}
}
