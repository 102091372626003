

.c-section--news{
	margin-top: 53px;
	@include mqPc {
		margin-top: 78px;
	}
}

.c-section--about{
	margin-top: 42px;
	@include mqPc {
		margin-top: 60px;
	}
}
.c-section--performers{
	margin-top: 40px  ;
	@include mqPc {
		margin-top: 50px;
	}
}
.c-section--program{
	margin-top: 53px;
	@include mqPc {
		margin-top: 43px;
	}
}
.c-section--profile{
	margin-top: 54px;
	@include mqPc {
		margin-top: 72px;
	}
}
.c-section--tickets{
	margin-top: 45px;
	@include mqPc {
		margin-top: 45px;
	}
}
.c-section--movie{
	margin-top: 30px;
	@include mqPc {
		margin-top: 42px;
	}
}
.c-section--link{
	margin-top: 70px;
	@include mqPc {
		margin-top: 90px;
	}
}


.c-section__contents{
	width: 88.88%;
	margin-left: auto;
	margin-right: auto;
	@include mqPc {
		padding-right: 40px;
		padding-left: 40px;
		width: 100%;
	}
	.c-news{
		padding: 0;
	}
	.c-heading{
		font-size: 1.5rem;
		line-height: 1.43;
		font-weight: 600;
		margin-top: fslh-spacing(10px,1.5rem,1.43);
		@include mqPc {
			margin-top: fslh-spacing(25px,2.4rem,1.6);
			font-size: 2.4rem;
			line-height: 1.6;
		}
	}
	.c-text{
		margin-top: fslh-spacing(10px,1.2rem,1.5);
		font-weight: 300;
		letter-spacing: -.089em;
		@include mqPc {
			letter-spacing: -.099em;
		}
	}
	.c-flex--col01{
		margin-top: fslh-spacing(10px,1.5rem,1.5);
		@include mqPc {
			margin-top: fslh-spacing(20px,1.5rem,1.5);
		}
	}
	.c-flex__item{
		font-size: 1.5rem;
		line-height: 1.5;
		&:nth-of-type(n+2){
			margin-top: fslh-spacing(10px,1.2rem,1.5);
			@include mqPc {
				margin-top: fslh-spacing(3px,1.2rem,1.5);
			}
		}
		@include mqPc {
			font-size: 2rem;
			line-height: 1.65;
			margin-top: fslh-spacing(10px,2rem,1.65);
		}
		small{
			display: block;
			font-size: 1.2rem;
			line-height: 1.875;
			margin-top: fslh-spacing(5px,1.2rem,1.875);
		}
	}
}

.c-stage-notice{
	font-size: 1rem;
	line-height: 1.8;
	font-weight: 300;
	margin-top: fslh-spacing(80px,1rem,1.75);
	@include mqPc {
		font-size: 1.3rem;
		line-height: 1.7;
		margin-top: 96px;
	}
	.c-section__contents{
		@include mqPc {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-top: 0;
		}
	}
	.c-text{
		@include mqPc {
			max-width: 770px;
			margin-top: 0;
			padding-right: 15px;
		}
		a{
			font-size: 1.2rem;
			line-height: 1.7;
			@include mqPc {
				font-size: 1.3rem;
			}
		}
	}
	p{
		&:nth-of-type(n+2){
			@include mqPc {
				margin-top: 2px;
			}
		}
		.c-flex__list{
			.c-flex__item{
				&:nth-of-type(1){
					@include mqPc {
					width: 55px;
					}
				}
				&:nth-of-type(2){
					@include mqPc {
					width: 30px;
					}
				}
			}
		}
		&.-organizer{
			margin-top: 15px;
			@include mqPc {
				margin-top: 20px;
			}
		}
		&.-copy {
			margin-top: 20px;
			letter-spacing: normal;
			@include mqPc {
				margin-top: 20px;
			}
		}
		.p-copy__separation{
			display: none;
			@include mqPc {
				display: inline-block;
			}
		}
	}

	.c-flex__list{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include mqPc {
			margin-top: 1.5em;
		}
	}
	.c-flex__item{
		&:nth-of-type(1){
			width: 20.85%;
		}
		&:nth-of-type(2){
			width: 13.4%;
			margin-left: 11.45%;
		}
		&:nth-of-type(3){
			width: 29.36%;
			margin-left: 16.25%;
			@include mqPc {
				width: 77px;
				margin-left: 0;
			}
		}
	}
	.c-logo{
		display: none;
		@include mqPc {
			display: inline-block;
			margin: 0 5px;
		}
		&:nth-of-type(1){
			@include mqPc {
				width: 50px;
			}
		}
		&:nth-of-type(2){
			@include mqPc {
				width: 30px;
			}

		}
	}
}

.c-section--movie{
	p{
		font-size: 1.6rem;
		@include mqPc {
			font-size: 2rem;
		}
	}
}

.c-block--heading{
	margin-top: 40px;
	width: 88.88%;
	margin-left: auto;
	margin-right: auto;
	border-top: 5px solid #9f8b49;
	padding-top: 20px;
	.c-img-heaidng{

	}
	.c-img-title{
		margin-top: 17px;
	}
	.c-img-text{
		margin-top: 25px;
		border-top: 1px solid #9f8b49;
		border-bottom: 1px solid #9f8b49;
	}
}

.c-section--news{
	.c-section__contents{
		margin-top: 6px;
		@include mqPc {
			margin-top: 25px;
		}
	}
}

.-hc{
	.c-section__contents{
	width: calc(100% - 26px);
	margin-left: auto;
	margin-right: auto;
		@include mqPc {
			padding-right: 40px;
			padding-left: 40px;
			width: 100%;
		}
	}
}
