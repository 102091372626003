.c-tickets {
  width: 88.88%;
  margin-right: auto;
  margin-left: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include mqPc {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }

  &__heading {
    font-size: 1.5rem;
    line-height: 1.4;
    padding: fslh-spacing(13px, 1.5rem, 1.4) 0;
    letter-spacing: -.08em;

    @include mqPc {
      font-size: 2.2rem;
      padding: fslh-spacing(15px, 2.2rem, 1.4) 0;
    }

    span {
      display: block;

      @include mqPc {
        display: inline-block;
      }
    }

    small {
      font-size: 1.1rem;
      line-height: 1.75;

      @include mqPc {
        font-size: 1.8rem;
      }
		}
		&.hc-color{
			color: #9F8B49;
		}
  }

  &__list {
    border-top: 1px solid #9F8B49;

    &+.c-tickets__list--dots {
      margin-top: 13px;

      @include mqPc {
        margin-top: 30px;
      }
    }

    &--dots {
      letter-spacing: -.07em;

      .c-tickets__item {
        border-bottom: none;

        @include mqPc {
          letter-spacing: -.02em;
          padding: fslh-spacing(15px, 2.2rem, 1.4) 0 0;
          margin-top: 7px;
        }

        &.c-tickets__item--large {
          .c-tickets__label {
            @include mqPc {
              flex-grow: 1;
            }
          }
				}
				&.--largeP{
					.c-tickets__label{
						@include mqPc {
							flex: 0 0 50.625%;
							max-width: 414px;
							letter-spacing: -.13em;
						}
					}
					.c-tickets__box{
						@include mqPc {
							flex: 0 0 49.375%;
							max-width: 395px;
							letter-spacing: -.06em;
						}
					}
				}
				&.--largeP02{
					.c-tickets__label{
						@include mqPc {
							flex: 1 1 auto;
						}
					}
					.c-tickets__box{
						@include mqPc {
							padding-left: 235px;
							width: 100%;
							flex: 0 0 100%;
							margin-top: 10px;
							max-width: unset;
						}
					}
				}
      }

      .c-tickets__label {
        position: relative;
        padding-left: 5px;
        display: block;

        @include mqPc {
					flex: 0 0 auto;
					min-width: 235px;
					padding-right: 20px;
        }

        &::before {
          content: "";
          width: 9px;
          height: 9px;
          background: #9F8B49;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          left: -5px;

          @include mqPc {
            width: 15px;
            height: 15px;
            left: -5px;
          }
        }

        span {}
      }

      .c-tickets__box {
        display: block;
        margin-top: 6px;

        @include mqPc {
					margin-top: 0;
					flex: 1 0 calc(100% - 280px);
					max-width: calc(100% - 280px);
        }

        p {}

				span {}


        small {}

        a {
          font-size: 1.5rem;
					display: inline-block;
          line-height: 1.8;

          @include mqPc {
            display: inline-block;
            font-size: 2.2rem;
          }
        }

        ul {
          margin-top: 5px;

          @include mqPc {
            margin-top: 12px;
          }
        }

        &>ul {
          li {
            @include mqPc {
              line-height: 1.9;
            }
          }
        }

        li {
          span {
            display: inline-block;
            font-size: 1.5rem;

            @include mqPc {
              font-size: 2.2rem;
              margin-left: 0;
						}
          }
				}
				.c-tikets__text--note{
					font-size: 1rem;
					@include mqPc {
						font-size: 1.3rem;
						letter-spacing: -.14em;
					}
				}
      }

      .c-tickets__item--large {
        @include mqPc {
          padding: fslh-spacing(21px, 2.2rem, 1.4) 0 fslh-spacing(5px, 1.5rem, 1.5) 0;
        }
      }
    }
  }

  &__contents {}

  &__item {
    display: block;
    font-size: 1.5rem;
    border-bottom: 1px solid #9F8B49;
    padding: fslh-spacing(5px, 1.5rem, 1.2) 0 fslh-spacing(5px, 1.5rem, 1.2) 0;

    @include mqPc {
      display: flex;
      font-size: 2.2rem;
      padding: fslh-spacing(14px, 2.2rem, 1.4) 0;
      flex-wrap: wrap;
    }

    &--large {
      padding: 3px 0 7px 0;

      @include mqPc {
        padding: fslh-spacing(24px, 2.2rem, 1.4) 0 fslh-spacing(13px, 1.5rem, 1.5) 0;
        letter-spacing: -.08em;
      }

      .c-tickets__label {
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: -.04em;

        @include mqPc {
          font-size: 2.2rem;
        }
      }

      .c-tickets__box--long {
        line-height: 1.4;

        @include mqPc {
          font-size: 1.5rem;
          line-height: 1.2;
        }
      }

      a {
        display: block;

        @include mqPc {
          display: inline-block;
        }
			}
			&+.c-tickets__item--large{
				border-top: 1px solid #9F8B49;
			}
		}

		&.--large{
			@include mqPc {
				display: block;
			}
			.c-tickets__label{
				@include mqPc {
					display: inline;
				}
			}
			.c-tickets__box{
				@include mqPc {
					display: inline-block;
				}
			}
		}

		&.-pickup {
			margin-top: 20px;
			border: 1px solid #9f8b49;
			background: #fff;
			color: #9f8b49;
			font-weight: 700;
			text-align: center;
			padding: 4.5px 5px;
			@include mqPc{
				text-align: center;
				justify-content: center;
				padding: 11.5px 10px;
			}
			&.-note{
				color: #ff3737;
				a{
					color: #ff3737;
				}
			}
		}
		&.-pickup--type02 {
			margin-top: 20px;
			border: 1px solid #9f8b49;
			background: #fff;
			color: #9f8b49;
			font-weight: 700;
			text-align: center;
			padding: 4.5px 5px;
			@include mqPc{
				text-align: center;
				justify-content: center;
				padding: 11.5px 10px;
			}

			span{
				font-size: 1.2rem;
				color: #000;
				@include mqPc{
					font-size: 1.8rem;
				}
			}
			a{
				color: #9f8b49;
			}
			&.-note{
				color: #ff3737;
				a{
					color: #ff3737;
				}
			}
		}
  }

  &__label {
    display: inline;

    @include mqPc {
      display: block;
      flex: 1 0 320px;
    }

    span {
      font-size: 1rem;
      line-height: 1.2;

      @include mqPc {
        font-size: 1.5rem;
        letter-spacing: -.06em;
      }
		}

		&--small{
			@include mqPc {
				flex: 0 0 110px;
			}
		}
  }

  &__box {
    display: inline;

    @include mqPc {
      display: block;
      flex: 1 0 475px;
    }

    p {
      display: inline;

      @include mqPc {
        display: block;
			}

			&+ p {
				margin-top: 7.5px;
				display: block;
				@include mqPc {
					margin-top: 10px;
				}
			}
    }

    span {
      font-size: 1.1rem;
      line-height: 1.8;
      display: block;
      letter-spacing: -.05em;

      @include mqPc {
        display: inline-block;
        font-size: 1.6rem;
        margin-left: 8px;
        line-height: 1.4;
			}
			&.-small{
				font-size: 1.2rem;
				@include mqPc {
					font-size: 1.3rem;
				}
			}
			&.u-i-block{
				display: inline-block;
			}
    }

    small {
      display: inline-block;
      font-size: 1.1rem;

      @include mqPc {
        font-size: 1.6rem;
        letter-spacing: -.06em;
      }
    }

    .code {
      display: inline-block;
    }

    &--long {
      small {
        font-weight: 300;
      }
    }
  }

  &__info {
    padding: fslh-spacing(7px, 1.5rem, 1.2) 0;
		letter-spacing: -.01em;
		margin-top: 20px;
		font-size: 1.5rem;

    @include mqPc {
			margin-top: 20px;
			font-size: 2.2rem;
    }

    small {
      font-weight: 300;
      font-size: 1.1rem;

      @include mqPc {
        font-size: 1.5rem;
      }
    }

    span {
      a {
        font-size: 1.1rem;
        letter-spacing: -.05em;
        line-height: 1.8;

        @include mqPc {
          display: inline-block;
          margin-left: 8px;
          font-size: 1.6rem;
          line-height: 1.4;
        }
      }
    }

    &:nth-of-type(n+2) {
      margin-top: fslh-spacing(7px, 1.5rem, 1.2);

      @include mqPc {
        margin-top: fslh-spacing(5px, 1.6rem, 1.2);
      }
    }

    &+.c-tickets__list--dots {
      margin-top: fslh-spacing(15px, 1.5rem, 1.2);

      @include mqPc {
        margin-top: fslh-spacing(10px, 2.2rem, 1.4);
      }
    }

    &--big {
      font-size: 1.5rem;
      line-height: 1.6;

      @include mqPc {
        font-size: 2.2rem;
      }
    }
		&.-note{
			color: #ff3737;
			a{
				color: #ff3737;
			}
		}
  }
}

.color--col30-31 {
  color: #51318F;
}

.vol28 {
  .c-tickets__heading {
    color: #E5004F;
  }
}

.vol29 {
  .c-tickets__heading {
    color: #f08300;
  }
}

.vol30-31 {
  .c-tickets__heading {
    color: #51318F;
  }
}


.c-tickets__heading,
.c-tickets__item,
.c-tickets__item--large {
  &.-hc-color {
    color: #9F8B49;
  }

  &.-is-21vol01 {
    color: #00B1A9;
  }

  &.-is-21vol02 {
    color: #EF8454;
  }

  &.-is-21vol03 {
    color: #E5006E;
  }

  &.-is-21vol04 {
    color: #6ABD79;
  }

  &.-is-21vol05 {
    color: #54C3F1;
  }

  &.-is-21vol06 {
    color: #F091A0;
  }

  &.-is-21vol07 {
    color: #837FB6;
  }
}


.c-tikets__text--note {
  font-size: 1rem;
  font-weight: 300;
  padding: 6px 0 0 0;

  @include mqPc {
    padding-bottom: 21px;
    padding-top: 16px;
    margin-top: 0px;
    font-size: 1.5rem;
  }

  &+.c-tickets__list--dots {
    margin-top: 13px;

    @include mqPc {
      margin-top: 13px;
      border-top: 1px solid #9F8B49;
    }
  }
}

.c-tikets--english {
  margin-top: 10px;
  line-height: 1.5;

  @include mqPc {
    margin-top: 0px;
  }

  .c-tikets--english__subhead {
    display: block;
    margin-top: 5px;
  }

  small {
    line-height: 1.2;
    font-weight: 300;
    display: block;

    @include mqPc {
      line-height: 1.8;
      display: block;
      font-size: 1.5rem;
    }
  }

  &+.c-tickets__list--mother {
    margin-top: 12px;

    @include mqPc {
      margin-top: 27px;
    }
  }

  p {
    @include mqPc {
      display: inline-block;
      line-height: 2;
		}
		a{
			display: inline-block;
		}
	}
	&+.c-tikets--english{
		@include mqPc {
			margin-top: 16px;
		}
	}
}

.c-tickets__list--mother {
  border-top: 1px solid #9F8B49;
  font-size: 1.5rem;
  line-height: 1.33;
  padding-bottom: fslh-spacing(10px, 1.5rem, 1.33);

  @include mqPc {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }

  .c-tickets__item {
    padding-bottom: fslh-spacing(15px, 1.5rem, 1.33);

    @include mqPc {
      padding-top: 13px;
      padding-bottom: 8px;
    }
  }

  .c-tickets__label {
    background: url(./../images/heading_img_icon_mother.svg) no-repeat left center;
    background-size: 17px 17px;
    color: #9F8B49;
    padding-left: 20px;
    font-size: 1.5rem;
    line-height: 2;
    margin-top: fslh-spacing(10px, 1.5rem, 2);
    display: block;

    @include mqPc {
      font-size: 2.2rem;
      line-height: 1.8;
      padding-left: 35px;
      padding-right: 20px;
      background-size: 33px 23px;
      background-position: 0px 7px;
      flex: 0 1 255px;
      max-width: 255px;
      letter-spacing: -.099em;

      &::after {
        display: none;
      }
    }
  }

  .c-tickets__box {
    line-height: 1.6;

    @include mqPc {
      line-height: 1.9;
    }

    a {
      display: inline;
		}
  }
}

.c-relatedEevents {
  @include mqPc {
    position: relative;

    &::after,
    &::before {
      display: block;
      position: absolute;
      bottom: -20px;
      width: 17px;
      height: 17px;
      border-style: solid;
      content: "";
      border-width: 17px 17px 0 0;
      border-color: transparent #9F8B49 transparent transparent;
    }

    &::after {
      left: 0;
      transform: rotate(180deg);
    }

    &::before {
      right: 0;
      transform: rotate(-90deg);
    }
  }
}

.c-relatedEevents {
  @include mqPc {

    &::after,
    &::before {
      display: block;
      position: absolute;
      top: 0px;
      width: 17px;
      height: 17px;
      border-style: solid;
      content: "";
      border-width: 17px 17px 0 0;
      border-color: transparent #9F8B49 transparent transparent;
    }

    &::after {
      left: 0;
      transform: rotate(180deg);
    }

    &::before {
      right: 0;
      transform: rotate(-90deg);
    }
  }

  .c-tickets__info {
    letter-spacing: -.01em;
  }
}

.c-relatedEevents__contents {
  @include mqPc {

    &::after,
    &::before {
      display: block;
      position: absolute;
      bottom: -20px;
      width: 17px;
      height: 17px;
      border-style: solid;
      content: "";
      border-width: 17px 17px 0 0;
      border-color: transparent #9F8B49 transparent transparent;
    }

    &::after {
      left: -28px;
      transform: rotate(90deg);
    }

    &::before {
      right: -20px;
      transform: rotate(0deg);
    }
  }
}

.c-relatedEevents {
  margin-top: 45px;

  @include mqPc {
    margin-top: 59px;
    position: relative;
  }

  .c-tickets__list--dots {
    .c-tickets__item {
      padding: 6px 0 3px;

      @include mqPc {
        padding-top: fslh-spacing(0px, 2.2rem, 1.4)
      }

      .c-tickets__label {
        position: relative;

        &:after {
          content: "：";

          @include mqPc {
            display: none;
          }
        }
      }

      .c-tickets__label {
        @include mqPc {
          flex: 0 0 170px;
        }
      }

      .c-tickets__box {
        span {
          display: inline-block;

          a {
            font-size: 1.1rem;
            letter-spacing: -.05em;
            line-height: 1.8;

            @include mqPc {
              font-size: 1.6rem;
              line-height: 1.4;
            }
          }
        }

        @include mqPc {
          flex: 0 0 570px;
        }

        p {
          @include mqPc {
            display: block;
          }
        }
      }
    }
  }

  .c-tickets__label {
    display: inline-block;
  }

  .c-tickets__box {
    display: inline;

  }
}

.c-relatedEevents__inner {
  @include mqPc {
    position: relative;
    padding: 20px 19px 20px 28px;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border: 1px solid #9f8b49;
    }

    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::after {
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }
  }
}

.c-relatedEevents__label {
  background-size: contain;
  position: relative;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 8px 10px;
  text-align: center;
  border-left: 9px solid #9F8B49;
  border-right: 9px solid #9F8B49;
  box-sizing: content-box;
  color: #9F8B49;

  @include mqPc {
    padding: 0;
    border: none;
    font-size: 2.2rem;
    line-height: 1.7;
    text-align: left;
    order: 1;
    flex: 0 1 90px;
  }

  &::before {
    width: calc(100% + 4px);
    height: 2.5px;
    border: 1px solid #9F8B49;
    content: "";
    position: absolute;
    top: 0;
    left: -2px;

    @include mqPc {
      display: none;
    }
  }

  &::after {
    width: calc(100% + 4px);
    height: 2.5px;
    border: 1px solid #9F8B49;
    content: "";
    position: absolute;
    bottom: 0;
    left: -2px;

    @include mqPc {
      display: none;
    }
  }
}

.c-relatedEevents__sublabel {
  color: #9F8B49;
  font-size: 1.2rem;
  margin-top: 12px;
  letter-spacing: -.03em;

  @include mqPc {
    margin-top: 0;
    font-size: 2.2rem;
    line-height: 1.7;
    padding-left: 15px;
    margin-left: 15px;
    position: relative;
    order: 2;
    flex: 1 0 calc(150px + 90px);

    &::before {
      width: 1px;
      height: calc(100% - (2.2rem / 1.7));
      background: #9f8b49;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: calc((2.2rem / 1.7) / 2);
    }
  }
}

.c-relatedEevents__head {
  position: relative;
  z-index: 1;

  @include mqPc {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.c-relatedEevents__contents {
  position: relative;
  z-index: 1;

  .c-tickets__list--dots {
    .c-tickets__item {
      .c-tickets__box {
        ul {
          margin-top: 0;

          li {
            line-height: 1.6;

            @include mqPc {
              display: inline-block;
              letter-spacing: -.11em;
            }

            span {
              display: inline;
              line-height: 1.4;
              font-size: 1.1rem;

              @include mqPc {
                font-size: 1.6rem;
              }
            }

            &.u-break {
              display: none;

              @include mqPc {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

}

.c-relatedEevents__contents--images {
  @include mqPc {
    display: flex;
    margin-top: 10px;

    .c-tickets__list--dots {
      .c-tickets__item {
        padding-top: fslh-spacing(0px, 2.2rem, 1.4);
        margin-top: 0;

        .c-tickets__label {
          flex: 0 0 170px;
        }

        .c-tickets__box {
          flex: 0 0 375px;
        }

        &:nth-of-type(n+2) {
          margin-top: 12px;
        }
      }
    }
  }
}

.c-related-events__contents__image {
  @include mqPc {
    flex: 0 0 200px;
  }
}

.c-related-events__contents__text {
  @include mqPc {
    flex: 1 1 auto;
  }
}

.c-relatedEevents__heading {
  font-size: 1.9rem;
  line-height: 1.4;
  margin-top: 7px;

  @include mqPc {
    font-size: 3.4rem;
    margin-top: 5px;
    flex: 0 0 100%;
    order: 4;
  }

  span {
    font-size: 1.3rem;
    display: block;
    text-indent: -.5em;

    @include mqPc {
      font-size: 2.2rem;

    }
  }
}

.c-elatedEevents--cancellation {
  padding: 2px 5px;
  background: #e60012;
  color: #fff;
  font-size: 1.6rem;
  position: absolute;
  right: 0;
  top: 0;

  @include mqPc {
    position: static;
    font-size: 3rem;
    padding: 3px 5px;
    line-height: 1.4;
    order: 3;
    flex: 0 0 130px;
  }
}

.vol28 {
  .c-relatedEevents__heading {
    color: #E5004F;
  }
}

.vol29 {
  .c-tickets {
    @include mqPc {
      padding-bottom: 10px;
    }
  }

  .c-relatedEevents__heading {
    color: #F08300;
  }
}

.vol30-31 {
  .c-relatedEevents__heading {
    color: #51318F;
  }
}

.-is-22vol01 {
  .c-tickets__heading {
    color: #683B93;
  }
}
.-is-22vol02 {
  .c-tickets__heading {
    color: #ee782a;
  }
}
.-is-22vol03 {
  .c-tickets__heading {
    color: #e73357;
  }
}
.-is-22vol04 {
  .c-tickets__heading {
    color: #01b2a9;
  }
}

.c-tickets__list--mother {}

.access {
  .c-tickets__list--dots {
    .c-tickets__item {
      font-size: 1.2rem;

      @include mqPc {
        font-size: 1.6rem;
      }
    }

    .c-tickets__label {
      flex: 1 0 100%;
    }

    .c-tickets__box {
      flex: 1 0 100%;
      padding-left: 15px;
    }
  }
}

.-hc{
	.c-tickets {
		width: calc(100% - 26px);
		@include mqPc {
			width: 100%;
			padding-right: 40px;
			padding-left: 40px;
		}
	}
	&.-is-21vol01,
	&.-is-21vol03,
	&.-is-21vol04,
	&.-is-21vol05{
		.c-tickets__item.-pickup{
			font-size: 1.35rem;
			@include mqPc {
				font-size: 2.2rem;
			}
		}
	}
	&.-is-21vol02,
	&.-is-21vol06,
	&.-is-21vol07{
		.c-tickets__item.-pickup{
			font-size: 1.5rem;
			@include mqPc {
				font-size: 2.2rem;
			}
		}
	}

}

.c-tickets__heading--sub{
	color: #9F8B49;
	padding: 0 0 calc(8px - ((1.5rem * 1.2) - 1.5rem)/ 2) 0;
	border-top: 1px solid #9f8b49;
	font-size: 1.5rem;
	line-height: 1.6;
	letter-spacing: -.003em;
	@include mqPc {
		padding: calc(18px - ((2.2rem * 1.4) - 2.2rem)/ 2) 0;
    font-size: 2.2rem;
    line-height: 1.4;
	}
	&+.c-tickets__list--large,
	&+.c-tickets__list{
		border-top: 1px solid #9f8b49;
	}
	span{
		font-size: 1rem;
		@include mqPc {
			font-size: 1.3rem;
		}
	}
}

.c-tickets__list{
	&+.c-tickets__heading--sub{
		border-top: none;
		margin-top: 15px;
	}

	&--large{
		&+.c-tickets__list{
			border-top: none;
		}
		.c-tickets__item{
			justify-content: flex-start;
		}
		.c-tickets__label{
			flex: 1 0 auto;
			display: block;
			line-height: 1.3;
		}
		.c-tickets__box{
			flex: 1 1 auto;
			display: block;
			margin-top: 7.5px;
			@include mqPc {
				margin-top: 0;
			}
			p{
				display: inline-block;
			}
		}
	}
}


.c-tickets--table--small{
	font-size: 1.1rem;
	@include mqPc {
		font-size: 1.5rem;
	}
	table{

	}
	tr,
	td{
		padding: 0;
		vertical-align: top;
	}
	td{
		&:nth-of-type(1){
			padding-right: 5px;
			@include mqPc {
				padding-right: 10px;
			}
		}
	}
	&+.c-tikets--english{
		margin-top: 25px;
		@include mqPc {
			margin-top: 24px;
		}
	}
}
.c-tikets--organizer{
	font-size: 1rem;
	font-weight: 600;
	padding: 18px 0 18px;
	@include mqPc {
		font-size: 1.5rem;
		padding: 7px 0 40px;
	}
	span{
		display: block;
		@include mqPc {
			display: inline-block;
			margin-left: 15px;
		}
	}
}

.c-tickets__heading--sub{
	&.u-colorreset{
		color: unset;
	}
}
