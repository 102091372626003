.c-top-photoCopy{
	width: calc(100% - 30px);
	margin-right: auto;
	margin-left: auto;
	margin-top: 50px;
	@include mqPc {
		width: calc(100% - 70px);
		margin-top: 15px;
	}
	p{
		font-size: 9px;
		font-weight: 300;
	}

	&__separation{
		display: none;
		@include mqPc {
			display: inline-block;
		}
	}
}
