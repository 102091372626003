.c-news{
	padding: 0 15px;
	@include mqPc {
		padding: 0 40px;
	}
	.swiper-wrapper{
		padding-bottom: fslh-spacing(15px,1.5rem,1.5);
		@include mqPc {
			padding-bottom: fslh-spacing(30px,1.6rem,1.5);
		}
	}
	.swiper-pagination{
		@include mqPc {
		}
	}

	.swiper-pagination{
		margin-bottom:0px;
		.swiper-pagination-bullet{
			position: relative;
			width: 12.5px;
			height: 12.5px;
			margin: 0 5px 8.75px;
			padding: 5px;
			opacity: 1;
			box-sizing: content-box;
			@include mqPc {
				width: 20px;
				height: 20px;
				padding: 5px;
				margin-left: 6px;
				margin-right: 6px;
			}
			&::after{
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 12.5px;
				height: 12.5px;
				transform: translateX(-50%) translateY(-50%);
				border: 1px solid #9f8b49;
				border-radius: 50%;
				background: #fff;
				content: "";
				@include mqPc {
					width: 15px;
				height: 15px;
				}
			}


			&.swiper-pagination-bullet-active{
				&::after{
					background: #9F8B49;
				}
			}
		}
	}
	&+.p-media{
		margin-top: 35px;
		@include mqPc {
			margin-top: 57px;
		}
	}
}


.p-catch{
	&.c-news{
		margin: fslh-spacing(50px,1.2rem,1.5) 0 0 0;
	}
}
.c-news__item{
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.5;
	margin-top: fslh-spacing(5px,1.6rem,1.5);
	text-align: justify;
	@include mqPc {
		overflow: visible;
		max-height: none;
		font-size: 1.6rem;
		display: flex;
		margin-top: fslh-spacing(17px,1.6rem,1.5);
		&:nth-of-type(1){
			margin-top: 0;
		}
	}
	&:nth-of-type(n+2){
		margin-top: fslh-spacing(4px,1.2rem,1.5);
		@include mqPc {
			margin-top: fslh-spacing(17px,1.6rem,1.5);
		}
	}
	p{
		position: relative;
		max-height: calc(2* (1.2rem * 1.5));
		overflow: hidden;
		@include mqPc {
			height: auto;
			max-height: none;
			overflow: visible;
			display: block;
			max-width:calc(100% - 115px);
			width: calc(100% - 115px);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-break: break-all;
			padding-right: 15px;
			margin-top: 0;
			flex: 0 0 calc(100% - 115px);
		}
		&::before,
		&::after{
			position: absolute;
			background: #f9f8f3;
			pointer-events: none;
			@include mqPc {
				display: none;
			}
		}
		&::before{
			content: "…";
			right: 0;
			bottom: 0;
			@include mqPc {
				display: none;
			}
		}
		&::after{
			width: 100%;
			height: 100%;
			content: "";
			@include mqPc {
				display: none;
			}
		}
	}
	time{
		color: #9F8B49;
		display: block;
		@include mqPc {
			display: inline-block;
			width: 115px;
			flex: 0 0 115px;
			max-width: 115px;
		}
	}
}

.c-news__link{
	text-decoration: none;
	@include mqPc {
		display: flex;
		overflow: hidden;
	}
	p{
		text-decoration: underline;
	}
}
.c-news__category{
	display: inline-block;
	margin-right: 0;
	width: 80px;
	@include mqPc {
		width: 115px;
		padding-right: 5px;
	}
}



.c-news__category{
	&.-all{
		color: #Fa1414;
	}
	&.-vol28{
		color: #E5004F;
	}
	&.-vol29{
		color: #F08300;
	}
	&.-vol30-31{
		color: #51318F;
	}
	&.-is-21vol01{
	color: #00B1A9;
	}
	&.-is-21vol02{
		color: #EF8454;
	}
	&.-is-21vol03{
		color: #e5006e;
	}
	&.-is-21vol04{
		color:#6ABD79;
	}
	&.-is-21vol05{
		color: #54C3F1;
	}
	&.-is-21vol06{
		color: #F091A0;
	}
	&.-is-21vol07{
		color: #837FB6;
	}

	&.-is-22vol01{
		color: #683B92;
	}
	&.-is-22vol02{
		color: #ED782B;
	}
	&.-is-22vol03{
		color: #E63462;
	}
	&.-is-22vol04{
		color: #00B0A8;
	}
}


.vol29{
	.c-news .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after{
		background: #E94709;
	}
	.c-news .swiper-pagination .swiper-pagination-bullet::after{
		border-color: #E94709;
	}
}

.vol30-31{
	.c-news .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after{
		background: #51318f;
	}
	.c-news .swiper-pagination .swiper-pagination-bullet::after{
		border-color: #51318f;
	}
}


.p-news__detail{
	width: 84.375%;
	margin-right: auto;
	margin-left: auto;
	background: rgba(255,255,255,.7);
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.75;
	padding: 0 15px 15px;
	text-align: justify;
	@include mqPc {
		width: 100%;
		max-width: 880px;
		padding: 0 40px 30px;
	}
	@media screen and (min-width: 769px) and (max-width: 1000px) {
		width: calc(100% - 120px);
		margin: 0 60px;
	}
	&+.l-container{
		margin-top: 25px;
		@include mqPc {
			margin-top: 45px;
		}
	}
}

.p-news__head{
	time{
		text-align: right;
		display: block;
		color: #9F8B49;
		@include mqPc {
			font-size: 1.4rem;
			line-height: 2.78;
		}
	}
}

.p-news__title{
	font-size: 1.7rem;
	line-height: 1.4;
	margin-top: fslh-spacing(22.5px,1.7rem,1.4);
	word-break: break-all;
	@include mqPc {
		font-size: 2.6rem;
		margin-top: fslh-spacing(25px,2.6rem,1.5);
	}
}
.p-news__contents{
	margin-top: 20px;
	word-break: break-all;
	@include mqPc {
		margin-top: 35px;
	}
	img{
		width: auto;
		max-width: 100%;
		height: auto;
	}
}

.p-news__text{
	font-size: 1.2rem;
	line-height: 1.75;
	font-weight: 300;
	*{
		max-width: 100%;
	}
	@include mqPc {
		font-size: 1.6rem;
		line-height: 1.875;
	}
	p{
		&+p{
			margin-top: fslh-spacing(12.5px,1.2rem,1.75);
		}
	}
	img{
		width: auto;
		text-align: center;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		&+img{
			margin-top: fslh-spacing(25px,1.2rem,1.75);
		}
		&+p{
			margin-top: fslh-spacing(12.5px,1.2rem,1.75);
		}
	}
	b, strong{
		font-weight: bold;
	}
	em{
		font-style: italic;
	}
	ul{
		list-style: disc;
		list-style-position: inside;
	}
	ol{
		list-style: decimal;
		list-style-position: inside;
	}
	blockquote{
		display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
	}

	input[name="post_password"] {
		border-bottom: 1px solid #9f8b49;
		background: #fff;
	}
	input[type="submit"]{
		padding: 2px 14px;
		margin-left: 15px;
		background: #9f8b49;
		color: #fff;
		border-radius: 5px;
	}
}
