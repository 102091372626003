.p-movie__list{
	@include mqPc {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		width: calc(100% + 50px);
		margin-left: -25px;
		margin-top: 25px;
	}
}
.c-movie__item{
	margin-top: 12px;
	@include mqPc {
		width: calc(50% - 50px);
		margin: 0 25px;
	}
	&:nth-of-type(n+3){
		@include mqPc {
			margin-top: 40px;
		}
	}
	video{
		width: 100%;
	}
	p{
		font-size: 1.2rem;
		line-height: 1.8;
		font-weight: 300;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: break-all;
		@include mqPc {
			font-size: 1.6rem;
			line-height: 1.4;
		}
	}
	iframe{
		width: 100%;
    min-height: 140px;
    max-height: 280px;
		@include mqPc {
			max-width: 375px;
			height: 208px;
		}
	}
}
.c-movie__link{
	overflow: hidden;

	@include mqPc {
		img{
			transition: transform .4s;
		}
		&:hover{
			img{
				transform: scale(1.1);
				transition: transform .4s;
			}
		}
	}
}
.c-section--link{
	.c-buttons{
		@include mqPc {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.p-movie__list__text{
	margin: 7px 0 12px;
	font-size: 1.6rem;
	@include mqPc {
		margin: 29px 0 30px;
	}
}
