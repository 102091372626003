.p-program{
	padding-top: 12px;
	padding-bottom: 12px;
	-moz-osx-font-smoothing: inherit;
	-webkit-font-smoothing: subpixel-antialiased;
	@include mqPc {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	.c-section__heading {
		margin-top: 0;
		padding-top: 0px;
		@include mqPc {
			padding-top: 0;
		}
	}
}

.c-program__list{
	border-top: 1px solid #fff;
	width: 88.88%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 7px;
	@include mqPc {
    width: calc(100% - 40px);
    margin-top: 23px;
    margin-right: 0;
    padding-right: 40px;
    padding-left: 30px;
    border-top: none;
    border-left: 1px solid #fff;
	}
}


.c-program__item{
	border-bottom: 1px solid #fff;
	padding: fslh-spacing(6px,1.2rem,1.5) 0 fslh-spacing(7px,1.2rem,1.35);
	@include mqPc {
		padding: fslh-spacing(16px,1.2rem,1.5) 0 11px;
	}
	&:nth-of-type(1){
		@include mqPc {
			border-top: 1px solid #fff;
			padding: fslh-spacing(12.5px,1.2rem,1.5) 0 12px;
		}
	}
	&:nth-last-of-type(1){
		@include mqPc {
			border-bottom: none;
		}
	}
	p{
		color: #fff;
		line-height: 1.53;
		font-size: 1.4rem;
		letter-spacing: -.03em;
		@include mqPc {
			font-size: 2rem;
			line-height: 1.6;
			letter-spacing: -.01em;
		}
	}
	span{
		display: block;
		padding-top: fslh-spacing(4.5px,1.2rem,1.35);
		line-height: 1.35;
		letter-spacing: -.013em;
		@include mqPc {
			font-size: 1.7rem;
			line-height: 1.3;
			padding-top: 0;
		}
	}
	&.c-program__item--head{
		p{
			font-size: 1.6rem;
			@include mqPc {
				font-size: 2.2rem;
			}
		}
	}
	&--note{
		border-bottom: none;
	}
}

.c-program__note{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	small{
		color: #fff;
		font-size: 1rem;
		@include mqPc {
		font-size: 1.2rem;
		}
		line-height: 1.5;
		margin-top: 12px;
		padding-bottom: 0;
		display: block;
		@include mqPc {
			margin-top: 18px;
			padding-bottom: 0px;
		}
	}
}

.vol28{
	.p-program{
		background: #E5004F;
	}
	.c-program__item{
		span{
			color: #f3b0af;
		}
	}
}


.vol29{
	.p-program{
		background: #F08300;
	}
	.c-program__item{
		span{
			color: #f4d4ab;
		}
	}
}

.vol30-31{
	.p-program{
		background: #51318F;
	}
	.c-program__item{
		span{
			color: #c3b5d8;
		}
	}
}


.-hc{
	.c-program__list{
		width: calc(100% - 26px);
		margin-left: auto;
		margin-right: auto;
		@media screen and (min-width: 480px) and (max-width: 768px){
			margin-top: 20px;
		}
		@include mqPc {
			width: calc(100% - 40px);
			margin-top: 23px;
			margin-right: 0;
			padding-right: 40px;
			padding-left: 30px;
		}
	}
	.c-program__item{
		.c-program__list{
			width: 100%;
			padding-left: 10px;
			border-top: none;
			margin-top: 15px;
			@include mqPc {
				border-left: none;
				border-top: none;
				border-top: none;
				padding-left: 15px;
				padding-right: 0;
				margin-top: 0;
			}
			.c-program__item{
				border-bottom: none;
				padding-bottom: 0;
				@include mqPc {
					padding-top: 5px;
				}
				&:nth-of-type(1){
					border-top: none;
				}
				span{
					padding-top: 0;
				}
			}
		}
	}
}

.-hc{
	.c-program__note{
		small{
			font-size: 1rem;
			font-weight: 600;
			@include mqPc {
				font-size: 1.4rem;
			}
		}
	}
}

.-is-21vol01{
	.p-program{
		background: #00B1A9;
	}
	.c-program__item{
		span{
			color:#7ed4cf;
		}
	}
}
.-is-21vol02{
	.p-program{
		background: #EF8454;
	}
	.c-program__item{
		span{
			color:#f4bea4;
		}
	}
}
.-is-21vol03{
	.p-program{
		background: #E5006E;
	}
	.c-program__item{
		span{
			color: #f19db4;
		}
	}
}
.-is-21vol04{
	.p-program{
		background: #6ABD79;;
	}
	.c-program__item{
		span{
			color:#b2dab7;
		}
	}
}
.-is-21vol05{
	.p-program{
		background: #54C3F1;
	}
	.c-program__item{
		span{
			color:#a6ddf3;
		}
	}
}
.-is-21vol06{
	.p-program{
		background: #F091A0;
	}
	.c-program__item{
		span{
			color:#f4c4ca;
		}
	}
}
.-is-21vol07{
	.p-program{
		background: #837FB6;
	}
	.c-program__item{
		span{
			color:#bebbd4;
		}
	}
}


