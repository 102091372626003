.u-pc{
	display: none;
	@include mqPc {
		display: block;
	}
}
.u-sp{
	display: block;
	@include mqPc {
		display: none;
	}
}

.u-text--center{
	text-align: center;
}

.u-ib{
	display: inline-block;
}

.u-letter-shrink01{
	letter-spacing: -.01em;
}
.u-letter-shrink02{
	letter-spacing: -.02em;
}
.u-letter-shrink03{
	letter-spacing: -.03em;
}
.u-letter-shrink04{
	letter-spacing: -.04em;
}
.u-letter-shrink05{
	letter-spacing: -.05em;
}
.u-letter-shrink06{
	letter-spacing: -.06em;
}
.u-letter-shrink07{
	letter-spacing: -.07em;
}
.u-letter-shrink08{
	letter-spacing: -.08em;
}
